.k-login-v2--enabled {
    background-color: var(--white);
}

// .k-login-v2 {
// 	padding: 3rem;

// Head
.k-login-v2__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;

    // Logo
    .k-login-v2__head-logo {
        > a {
            display: inline-block;
        }
    }

    // Signup
    .k-login-v2__head-signup {
        display: flex;
        align-items: center;

        > span {
            margin-bottom: 0;
            color: #9d9fb1;
            font-size: 1.1rem;
            padding-right: 0.5rem;
            font-weight: 500;
        }

        > a {
            font-weight: 500;
            font-size: 1.1rem;
        }
    }
}

// Body
.k-login-v2__body {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9fc;

    //  Wrapper
    .k-login-v2__body-wrapper {
        width: 50%;
        justify-content: flex-end;
        display: flex;

        // Container
        .k-login-v2__body-container {
            width: 100%;
            max-width: 500px;
            box-shadow: 2px 2px 2px 0 rgba(105, 63, 63, 0.05);
            // background-color: #f9f9fc;
            border-radius: 0.6rem;

            // Title
            .k-login-v2__body-title {
                text-align: center;
                > h3 {
                    color: #636576;
                    margin-top: 3rem;
                    font-size: 1.5rem;
                }
            }

            // Form
            .k-login-v2__body-form {
                padding: 3rem 4rem;

                &.k-login-v2__body-form--border {
                    .form-group {
                        .form-control {
                            border-top: 0;
                            border-left: 0;
                            border-right: 0;
                            border-radius: 0;
                            padding-left: 0;
                        }
                    }
                }
            }

            // Action
            .k-login-v2__body-action {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 4rem;

                a {
                    display: inline-block;

                    span {
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }

                .btn {
                    padding: 1rem 3.5rem;
                    border-radius: 60px;
                    color: var(--white);
                    font-size: 1.1rem;
                }
            }

            .k-login-v2__body-subtitle {
                text-align: center;
                color: #a5a7bb;
                font-size: 1.3rem;
            }

            // Options
            .k-login-v2__body-options {
                display: flex;
                justify-content: space-between;
                padding: 2rem 4rem 3rem 4rem;

                > a {
                    border-radius: 60px;
                    background-color: #202374;
                    flex: 1;
                    font-weight: 600;
                    font-size: 1rem;
                    padding: 1rem 0;

                    > i {
                        font-size: 1.2rem;
                        color: var(--white);
                        padding-right: 0.4rem;
                        font-weight: 900;
                    }

                    &:not(:first-child):not(:last-child) {
                        margin: 0 2rem;
                    }
                }
            }
        }
    }

    // Pic
    .k-login-v2__body-pic {
        width: 50%;
        margin-left: 8rem;
        justify-content: flex-start;
        display: flex;

        > img {
            width: 100%;
            max-width: 500px;
        }
    }
}

// Footer
.k-login-v2__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Info
    .k-login-v2__footer-info {
        > a {
            font-weight: 500;
            font-size: 1rem;
            color: #a5a7bb;
        }
    }

    // Link
    .k-login-v2__footer-link {
        order: 2;

        > a {
            color: #9c9fb1;
            font-weight: 500;
            font-size: 0.93rem;

            &:not(:first-child):not(:last-child) {
                margin: 0 2rem;
            }
        }
    }
}

// // State font color
$k-state-colors: (
    // Keen states
        brand:
        (
            base: #536be2,
            inverse: #ffffff,
        ),
    metal: (
        base: #d3dae6,
        inverse: #586272,
    ),
    light: (
        base: #ffffff,
        inverse: #282a3c,
    ),
    dark: (
        base: lighten(#4c467a, 12%),
        inverse: #ffffff,
    ),
    accent: (
        base: #00c5dc,
        inverse: #ffffff,
    ),
    focus: (
        base: #9816f4,
        inverse: #ffffff,
    ),
    // Bootstrap states
        primary:
        (
            base: #5867dd,
            inverse: #ffffff,
        ),
    success: (
        base: #1dc9b7,
        inverse: #ffffff,
    ),
    info: (
        base: #5578eb,
        inverse: #ffffff,
    ),
    warning: (
        base: #ffb822,
        inverse: #111111,
    ),
    danger: (
        base: #fd397a,
        inverse: #ffffff,
    )
);

@each $name, $color in $k-state-colors {
    .k-login-v2__body {
        .k-login-v2__body-wrapper {
            .k-login-v2__body-container {
                .k-login-v2__body-options {
                    .k-login-v2__body-option--#{$name} {
                        background-color: lighten(k-get($color, base), 33%);

                        &:hover {
                            background-color: lighten(k-get($color, base), 28%);
                        }
                    }
                }
            }
        }
    }
}

// Tablet & Mobile Mode
@include k-tablet-and-mobile {
    .k-login-v2 {
        padding: 3rem 1rem 2rem 1rem;

        .k-login-v2__head {
            .k-login-v2__head-logo {
            }

            .k-login-v2__head-signup {
                > span {
                    padding-left: 1rem;
                }

                > a {
                }
            }
        }

        .k-login-v2__body {
            display: flex;
            flex-direction: column;
            margin-top: 3rem;

            .k-login-v2__body-wrapper {
                padding-left: 0;
                width: 100%;
                display: flex;
                justify-content: center;

                .k-login-v2__body-container {
                    margin-left: 0;

                    .k-login-v2__body-title {
                        padding-top: 1rem;
                    }

                    .k-login-v2__body-form {
                        padding: 3rem 1rem;
                    }

                    .k-login-v2__body-action {
                        padding: 0 1rem;
                    }

                    .k-login-v2__body-options {
                        margin-top: 3rem;
                        padding: 2rem 1rem;

                        > a {
                            > i {
                                padding: 0.4rem 0.3rem 0.4rem 0;
                            }

                            &:not(:first-child):not(:last-child) {
                                margin: 0 1rem;
                            }
                        }
                    }
                }
            }

            .k-login-v2__body-pic {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-left: 0;
                padding: 4rem 0;

                > img {
                    width: 100%;
                    max-width: 500px;
                    height: 100%;
                }
            }
        }

        .k-login-v2__footer {
            padding-bottom: 2rem;

            .k-login-v2__footer-info {
                > a {
                    padding-right: 0.5rem;
                }
            }

            .k-login-v2__footer-link {
                > a {
                    &:not(:first-child):not(:last-child) {
                        margin: 0 1rem;
                    }
                }
            }
        }
    }
}

// Mobile Mode
@include k-mobile {
    .k-login-v2 {
        .k-login-v2__head {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .k-login-v2__head-logo {
                flex: 1 100%;
                padding-left: 3rem;
            }

            .k-login-v2__head-signup {
                flex: 1 100%;
                text-align: right;
                padding: 3rem 0 0 3rem;

                > span {
                    padding-left: 0;
                }
            }
        }

        .k-login-v2__footer {
            flex-flow: column;

            .k-login-v2__footer-info {
            }

            .k-login-v2__footer-link {
                order: 0;
                margin-bottom: 1rem;
            }
        }
    }
}

[class*="Section__container___"] {
    @extend .k-login-v2__body;

    flex-direction: column !important;
    // display: block;
    align-items: stretch;
}

[class^="Toast__toast___"] {
    @extend .show, .fa-exclamation-circle;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    padding: 1.3rem 2rem;
    border-radius: 3px;
    margin: 0 auto 2rem auto;
    align-items: center !important;
    background-color: #f9f9fc;

    &::before {
        @extend .fas;

        font-size: 22px;
        margin-right: 16px;
    }

    width: 500px !important;
    color: #5578eb !important;
    box-shadow: none !important;
    margin-bottom: -82px !important;
    margin-top: 20px;
    position: static !important;
}

[class*="Form__formContainer___"] {
    @extend .k-login-v2__body-wrapper;

    justify-content: center !important;
    margin-top: 100px !important;
}

[class*="Form__formSection___"] {
    @extend .k-login-v2__body-container;

    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width: 500px !important;

    &::before {
        margin: 1rem auto 3rem;
        display: block;
        content: "";
        background-image: url(../../images/logo-light.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: 30px;
    }

    a {
        // @extend .k-link, .k-link--brand;

        color:var(--primary500) !important;
    }
}

[class*="Section__sectionHeader___"] {
    @extend .k-login-v2__body-title;

    color: #636576 !important;
    padding: 0 4rem;
    margin-bottom: 0;
}

[class*="Section__sectionBody___"] {
    @extend .k-login-v2__body-form, .k-form, .k-login-v2__body-form--border;
}

[class*="Input__inputLabel___"] {
    display: none;
    margin-bottom: 0;
}

[class*="Input__label___"] {
    margin-bottom: 0;
}

[class*="Input__radio___"] {
    vertical-align: middle;
}

[class*="Form__formField___"] {
    @extend .form-group;

    margin-bottom: 2rem;

    input,
    textarea,
    select {
        @extend .form-control;

        padding: 0.65rem 1rem;

        &:focus {
            box-shadow: none;
            outline: 0;
        }

        /* Change the white to any color ;) */
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            -webkit-text-fill-color: #152939 !important;
        }
    }

    select {
        padding: 0.65rem 1rem !important;
    }

    // [class*=Hint__hint___] {
    //     margin-top: 2rem;
    // }
}

[class*="Section__sectionFooter___"] {
    @extend .k-login-v2__body-action;

    a {
        // @extend .d-block, .k-link, .k-link--brand;

        color: var(--primary500) !important;
    }

    button,
    [class*="Button__button___"] {
        padding: 1rem 3.5rem;
        border-radius: 0.25rem;
        color: var(--white);
        font-size: 1rem;
        cursor: pointer;
        align-items: center;
        background-color: var(--primary500);
        border-color: var(--primary500);
        font-weight: bold;

        &:hover {
            background-color: var(--primary400) !important;
            border-color: var(--primary400) !important;
        }

        &:active {
            background-color: var(--primary600) !important;
            border-color: var(--primary600) !important;
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.8;
            background-color: var(--gray200) !important;
            display: flex;
            align-items: center;

            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            &::before {
                content: "";
                display: inline-block;
                width: 17px;
                height: 17px;
                border: 2px solid white;
                border-left-color: transparent;
                border-radius: 50%;
                animation: rotate 0.7s linear infinite;
                margin-right: 15px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    [class^="Toast__toast___"] {
        width: calc(100% - 45px) !important;
    }

    [class*="Form__formContainer___"] {
        width: auto !important;
    }

    [class*="Form__formSection___"] {
        min-width: auto !important;
    }
}

@include k-tablet {
    [class*="Section__sectionFooter___"] {
        button {
            margin-left: 2rem;
        }
    }
}

@media only screen and (max-width: 599px) {
    [class*="Section__sectionFooter___"] {
        button {
            margin-left: 0;
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 780px) {
    [class*="Section__sectionFooter___"] {
        button {
            margin-left: 2rem;
        }
    }
}
