.orders {
    position: relative;

    .k-portlet__body {
        padding: 0;
    }

    .k-portlet__content {
        display: grid;
        grid-template-columns: 273px 1fr;

        @media (min-width: 1556px) {
            grid-template-columns: 338px 1fr;
        }
    }

    .table__wrapper {
        flex-grow: 1;
        // overflow-x: scroll;
        display: grid !important;
        width: 100%;
    }
}

.order__table_row_primary {
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
}

.order__table_row_secondary {
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--gray400);

    &_with_badge {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 20px;

        span:last-of-type() {
            min-width: 100px;
        }
    }
}

.order__table_copy_action {
    border: none;
    background: transparent;

    &:hover,
    &:active,
    &:focus {
        background: transparent !important;

        svg {
            path {
                stroke: var(--primary300);
            }
        }
    }
}

.order__details {
    &.modal-body {
        height: 70%;
    }

    .k-portlet__head {
        border-bottom: 0;
        background-color: var(--white);
    }

    .k-section {
        padding-bottom: 40px;
    }

    .k-portlet__body {
        padding: 0;
    }

    .nav-tabs {
        position: relative;
        background-color: var(--white);
        position: sticky;
        top: 110px;
        z-index: 89;

        @media (min-width: 768px) {
            top: 190px;
        }
    }

    .tab-content {
        height: 100%;
        padding: 32px 12px !important;
    }

    .nav-tabs::after {
        content: "";
        z-index: 89;
        bottom: -33px;
        position: absolute;
        display: block;
        width: 100%;
        height: 32px;
        margin-left: -24px;
        background-color: var(--white);
    }

    .order__status {
        display: flex;
        gap: 16px;
    }

    .order__type {
        font-size: $text-xl !important;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: var(--gray700);
        font-weight: 700;
    }

    .order_sticky_header {
        position: sticky;
        top: 260px;
        background-color: var(--white);
        z-index: 95;
        padding: 1rem 0;
        min-height: 76px;
    }

    .shadow {
        background-color: var(--white);
        box-shadow: 0 4px 2px -2px #efefef !important;
        width: 100%;
        height: 0.5rem;
        position: sticky;
        z-index: 3;
        top: 330px;
    }

    .shadow_cover {
        background-color: var(--white);
        width: 100%;
        height: 1rem;
        margin-top: -0.5rem;
        position: relative;
        z-index: 3;
    }

    .feedback_message {
        padding: 0 0.75rem;
    }

    .order__details_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .order__details_toolbar,
        .order__details_actions {
            display: flex;
            gap: 16px;

            &_hidden {
                display: none;
            }
        }
    }

    .btn__options {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 11px;
        height: 48px;
        min-width: 120px;
    }

    .btn__edit {
        width: 124px;
    }

    .btn__toolbar {
        min-width: 176px;
        height: 40px;
    }

    .btn_check {
        width: 100%;
        margin-bottom: 0;
        padding: 7px 14px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background-color: var(--gray50);
        border: 1px solid var(--gray200);

        label {
            font-size: $text-sm;
        }

        button {
            background-color: var(--gray50);
        }

        &:hover {
            background-color: var(--gray100);

            button {
                background-color: var(--gray100);
            }
        }
    }

    .order__details_toolbar {
        display: flex;
        gap: 16px;
    }

    .max-md {
        max-width: 640px;
    }

    .max-lg {
        max-width: 1200px;
    }

    .order__details_num {
        font-weight: 700;
        margin-bottom: 8px;
        line-height: 32px;
        font-size: $text-2xl;
    }

    .order__details_date {
        display: flex;
        margin-bottom: 16px;
        font-size: $text-base;
        line-height: 24px;
        color: var(--gray700) !important;
        gap: 5px;
    }

    .order__detail_label {
        margin-bottom: 8px;
        font-size: $text-xs;
        color: var(--gray400);
        text-transform: uppercase;
    }

    h5 {
        font-size: $text-base !important;
        color: var(--gray700);
        padding: 12px 0;
        margin-bottom: 12px;
    }

    .order__detail_info_content {
        letter-spacing: -0.02em;
        line-height: 24px;

        .order__detail_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 16px;
        }

        .order__detail_info_item {
            width: 100%;
            display: grid;
            align-items: center;

            a {
                cursor: pointer;
                margin-right: auto;
                color: var(--gray800);
                background-position: center bottom;
                background-repeat: repeat-x;
                background-size: 4px 1px;
                background-image: linear-gradient(
                    to right,
                    rgb(135, 135, 135) 50%,
                    transparent 50%
                );

                &:hover {
                    color: var(--primary700);
                    background-image: linear-gradient(
                        to right,
                        var(--primary700) 50%,
                        transparent 50%
                    );
                }
            }

            .form-group {
                margin-bottom: 0 !important;
                flex: 1;
            }

            .order__detail_info_item__window {
                display: flex;
                gap: 0.5rem;

                .css-2b097c-container {
                    > div {
                        height: 41.25px;
                    }

                    .css-26l3qy-menu {
                        margin-top: 5px;
                        height: 220px;
                    }
                }
            }

            strong {
                flex-grow: 1;
                color: var(--gray400);
                font-weight: 400;
            }

            @media (min-width: 1336px) {
                grid-template-columns: 144px 1fr;
                gap: 2rem;
            }
        }
    }

    .content_delivery {
        padding-left: 15px;

        @media (max-width: 1024px) {
            margin-top: 28px;
        }

        @media (min-width: 1336px) {
            padding-left: 56px;
            margin: 0;
        }

        &_separator {
            padding-left: 56px;
            padding-bottom: 15px;
            border-left: 1px solid var(--gray100);
        }
    }

    .separator {
        @media (min-width: 1336px) {
            padding-right: 56px;
            align-items: stretch;
            border-right: 1px solid var(--gray100);
        }
    }

    .order_data__delivery_scan_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 26px;

        .products_slider {
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;

            .products_slider_btn {
                border: none;
                padding: 0 !important;
                height: 32px;

                &:hover,
                &:focus {
                    background-color: transparent !important;
                    border-color: transparent !important;
                }

                &:disabled {
                    background: transparent !important;
                    background-color: transparent !important;
                }

                i {
                    font-size: 2rem;
                }
            }

            .products_counter {
                height: 32px;
                min-width: 60px;
                background-color: #edeff5;
                font-size: 14;
                line-height: 20px;
                letter-spacing: 1%;
                color: var(--gray700);
                font-weight: 500;
                padding: 6px 12px;
                border-radius: 4px;
                margin-right: -12px;
                text-align: center;
            }

            .product_name {
                font-size: 20px;
                line-height: 32px;
                letter-spacing: 2%;
                color: var(--gray700);
                font-weight: 700;
            }
        }

        .product_details {
            display: flex;
            align-items: center;
            gap: 24px;
            margin-left: 123px;
            min-height: 32px;

            .scanned_info {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -2%;
                color: #727a94;
                font-weight: 400;
            }

            .verification {
                text-transform: uppercase;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1%;
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-weight: 500;
                border-radius: 2px;
                padding: 2px 11px;

                &_progress {
                    color: #2e59a3;
                    background-color: #e5efff;
                }

                &_success {
                    color: #3b9415;
                    background-color: #eefce8;
                }

                &_error {
                    color: #b84343;
                    background-color: #ffe7e7;
                }

                i {
                    padding-right: 0;
                }
            }
        }
    }

    .action_column {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        button {
            width: 36px;
            padding: 0.35rem 0.25rem;
            border-radius: 8px;

            i {
                padding-right: 0;
            }
        }
    }

    .order_data__delivery_scan_container {
        display: grid;
        gap: 16px;

        button {
            font-size: $text-xs;
            height: 42px !important;
        }

        input {
            border: 1px solid var(--gray200);
        }
    }

    .table-responsive,
    .react-bs-table-no-data,
    table {
        border-radius: 4px !important;
        margin-bottom: 0;

        tr {
            border: 1px solid var(--lines);
        }

        th {
            border: 0;
        }
    }

    .product__image {
        width: 60px !important;
        height: 60px !important;
    }

    .table__content_wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-right: 24px;
        margin: 0 12px;
        width: 100%;

        .table_actions {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }

    .quantity_content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        height: 39.2px;
        min-width: 80px;
        max-width: 120px !important;
    }

    .btn_small_action {
        display: flex;
        align-items: center;
        justify-content: center !important;
        height: 20px !important;
        width: 20px !important;
        padding: 2px !important;

        i {
            padding: 0;
            font-size: 10px !important;
            color: var(--gray500);
        }
    }

    .order__form_add_item {
        background-color: #f5f7fa;
        padding: 1rem;

        .add__item_form_headder {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 16px;

            button {
                border: none;
                padding: 0;
                background: transparent;
                cursor: pointer;
            }

            span {
                font-size: $text-base;
                line-height: 20px;
                font-weight: 500;
            }
        }

        .add__item_form_body {
            align-items: center;

            .products_select {
                min-width: 270px;
            }
        }

        label {
            text-transform: uppercase;
            color: var(--gray400);
            font-size: $text-xs;
        }

        button,
        input {
            height: 39.2px;
        }

        .checkbox {
            margin-bottom: 0;

            .unchecked,
            .checked {
                background-color: var(--gray50);
            }
        }

        .add__items_actions {
            display: flex;
            gap: 16px;
        }
    }

    .table__summary {
        border: 0 !important;
        width: 60%;

        @media (min-width: 700px) {
            width: 35%;
        }

        @media (min-width: 1280px) {
            width: 32%;
        }

        @media (min-width: 1536px) {
            width: 26%;
        }

        @media (min-width: 2008px) {
            width: 21%;
        }

        &_edit {
            @media (min-width: 2008px) {
                width: 19%;
            }
        }

        &_promo_code {
            width: 100%;

            @media (min-width: 700px) {
                width: 55%;
            }

            @media (min-width: 1280px) {
                width: 40%;
            }

            @media (min-width: 1536px) {
                width: 27.5%;
            }

            @media (min-width: 2008px) {
                width: 25.7%;
            }
        }

        &_promo_code_edit {
            @media (min-width: 2008px) {
                width: 25%;
            }
        }

        .form-group {
            margin-bottom: 0;

            input {
                height: 36px;
                margin-bottom: 10px;
            }
        }

        .sumary__less_border {
            border: 0 !important;

            td {
                font-size: $text-base !important;
                letter-spacing: -0.02em;
                line-height: 24px;
                color: var(--gray400);
                padding: 0 16px 16px !important;
                font-weight: 400 !important;
            }

            .td__promo_code {
                padding-top: 6px !important;
                vertical-align: top;
            }

            span {
                color: var(--gray700);
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    list-style: disc;
                    list-style-position: inside;
                    padding: 6px 0 4px !important;
                    color: var(--gray700);
                    font-size: $text-sm;

                    span {
                        position: relative;
                        left: -10px;
                    }

                    button {
                        position: relative;
                        float: right;
                        top: -2px;
                        padding: 1px;
                    }
                }

                li::marker {
                    color: var(--gray400) !important;
                }
            }
        }
    }
}

.modal__order_detail {
    height: 85vh;
    max-height: 1150px;

    @media (min-width: 576px) {
        max-width: 80vw;
    }

    @media (min-width: 1556px) {
        max-width: 75vw;
    }

    .content-placeholder.skeleton_btn {
        width: 180px;
    }

    .modal-header {
        border-bottom: 0;
        padding: 32px;
    }

    .order__details {
        padding: 0;

        .nav-tabs {
            padding: 0 32px;
            position: relative;
            background-color: var(--white);
            top: unset;
            z-index: unset;

            @media (min-width: 768px) {
                top: unset;
            }
        }

        .nav-tabs::after {
            margin-left: -32px;
        }

        .order_sticky_header {
            position: sticky;
            top: 0;
            background-color: var(--white);
            z-index: 95;
            padding: 1rem 0;
            min-height: 76px;
        }

        .shadow {
            background-color: var(--white);
            box-shadow: 0 4px 2px -2px #efefef !important;
            width: 100%;
            height: 0.5rem;
            position: sticky;
            z-index: 3;
            top: 5rem;
        }

        .shadow_cover {
            background-color: white;
            width: 100%;
            height: 1rem;
            margin-top: -0.5rem;
            position: relative;
            z-index: 3;
        }
    }

    .tab-content {
        padding: 32px 20px !important;
        margin-right: 5px;
        max-height: 430px;
        overflow-y: auto;

        @media (min-width: 1024px) {
            max-height: 824px;
        }

        @media (min-width: 1556px) {
            max-height: 1150px;
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray200);
            border-radius: 8px;
        }
    }

    .modal-footer {
        padding: 2rem;
        justify-content: space-between;

        .modal_footer__container {
            display: flex;
            justify-content: space-between;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 14px 16px !important;
            height: 48px !important;
            min-width: 176px;
        }
    }
}

.barcodes_list {
    list-style: none;
    padding: 0 !important;
    margin-bottom: 0 !important;
}

.dropdown-item--search-product {
    height: auto !important;
    display: flex;
    gap: 10px;
    cursor: pointer;
}
