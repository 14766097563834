.customers__content {
    .k-portlet__head-toolbar {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        @media (min-width: 768px) {
            flex-wrap: nowrap;
        }

        a {
            display: flex;
            align-items: center;
            margin-top: 0 !important;
        }

        .btn-group {
            height: 40px;

            button {
                height: 40px;
                padding: 10px 16px;
                min-width: 200px;

                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: space-between;
                border-right: none;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;

                &:hover {
                    cursor: pointer;
                }
            }

            div {
                min-width: 128px;
            }
        }

        .k-input-icon {
            display: flex;
            margin: 0;

            input {
                height: 40px;
                border-color: var(--gray200);
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;

                &:hover {
                    border-color: var(--gray600);
                }
            }

            .k-input-icon__icon {
                margin-left: 125px;
            }

            button {
                height: 40px;
                display: flex;
                align-items: center;
            }
        }

        .costumers__toolbar_actions {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            flex-grow: 1;

            @media (min-width: 768px) {
                flex-wrap: nowrap;
            }

            .btn {
                height: 40px;
                padding: 10px 16px;
                min-width: 128px;
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .status {
        max-width: 70px !important;
        padding: 2px 8px !important;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        line-height: 16px;
        font-weight: 500;
        font-size: $text-xs;
    }

    .status-success {
        @extend .status;
        background-color: var(--blue50);
        color: var(--blue200);
    }

    .status-secondary {
        @extend .status;
        background-color: var(--gray100);
        color: var(--gray500);
    }

    .badge__status {
        display: inherit;
    }
}

.customers__details {
    .k-portlet__head {
        border-bottom: 0;
        background-color: var(--white);
    }

    .k-portlet__body {
        padding: 0;
    }

    .account__status {
        margin: 16px auto 24px 0;
        flex-grow: 0;

        span {
            display: initial;
        }
    }

    .customers__name {
        font-size: $text-xl !important;
        align-items: center;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: var(--gray700);
        font-weight: 700;
        margin: 12px 0 24px;
        text-transform: capitalize;

        p {
            margin-bottom: 0;
        }
    }

    .nav-tabs {
        background-color: var(--white);
        position: sticky;
        top: 110px;
        z-index: 90;

        @media (min-width: 768px) {
            top: 173px;
        }
    }

    .tab-content {
        padding: 24px 12px !important;
    }

    .content-placeholder {
        margin-bottom: 20px;
    }

    .nav-tabs::after {
        content: "";
        z-index: 90;
        bottom: -25px;
        position: absolute;
        display: block;
        width: 100%;
        height: 24px;
        margin-left: -24px;
        background-color: var(--white);
    }

    .btn {
        height: 40px;
        padding: 10px 16px;
        min-width: 128px;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }

    .btn__geo_select {        
        height: 41.19px !important; 
        position: absolute;
        right: 12px;       
    }

    .input__geo_selected_area {
        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray200);
            border-radius: 6px;
        }

    }

    .message__geo_referent {
        color: var(--gray400);
        font-size: 12px;
        display: block;
        margin-bottom: 2px;
    }

    .btn__toolbar {
        height: 41.2px;
        padding: initial;
    }

    label {
        margin-bottom: 8px;
        color: var(--gray400);
        line-height: 16px;
        font-size: $text-xs;
        text-transform: uppercase;
        letter-spacing: 0.01em;
    }

    .input_email {
        border: 1px solid var(--gray200);
        height: 42px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background-color: var(--gray100);

        .form-control {
            border: 0;
            height: 39px;
            padding: 9px 0px 9px 14px;
        }

        .badge__status {
            margin-right: 10px;
            max-width: max-content;
        }

        &:hover {
            border: 1px solid var(--gray500) !important;
        }

        &_invalid {
            border: 1px solid var(--red100) !important;

            &:hover {
                border: 1px solid var(--red200) !important;
            }
        }
    }

    .btn-group {
        display: grid;
        border-radius: 4px;
        flex-grow: 1;

        .select_button {
            display: flex;
            justify-content: space-between;
            text-align: left;
            padding-right: 2rem;
        }

        &:hover {
            border: 1px solid var(--gray500);
        }

        &:invalid {
            border: 1px solid var(--red200);
        }
    }

    .account__info_table {
        row-gap: 32px;
        border: 1px solid var(--gray100);
        border-radius: 4px;
        padding: 26px 0;
        width: 100%;

        label,
        .checkbox {
            margin: 0;
        }

        .separator {
            margin-top: 16px;
            display: flex;
            height: auto;
            //margin: auto 0;
            width: 1px !important;
            border-left: 1px solid var(--gray100);
            align-items: stretch;
        }

        .col__info {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 0 32px;
            max-width: 420px;
            min-width: 300px;
            //border-right: 1px solid var(--gray100);
            flex-grow: 1;
            word-wrap: break-word;

            label {
                display: flex;
                justify-content: space-between;
            }

            ul {
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                margin: 0;
                padding: 0;
            }

            &_item {
                display: flex;
                column-gap: 8px;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;

                &_input {

                    input {
                        max-width: 80px;
                    }
                }

                &_placeholder {
                    font-size: $text-xs;
                    color: var(--gray300);
                }
            }

            button {
                height: 20px;
                min-width: 20px;
                max-width: 20px !important;
                padding: 0;

                &:hover {
                    svg {
                        * {
                            stroke: var(--primary400);
                        }
                    }
                }
            }
        }

        .content-placeholder {
            flex-grow: 1;
            margin-bottom: 0;
            padding: 0 10px;
        }

        .row__info {
            margin: 0 !important;
            display: flex;
            align-items: center;
            column-gap: 32px;
            row-gap: 8px;
            flex-wrap: wrap;
            width: 100%;
        }

        .row__info_marketing {
            @extend .row__info;
            column-gap: 10px;
        }

        .subrow__info {
            margin: 0 !important;
            display: flex;
            width: fit-content;
            align-items: center;
            padding-left: 0;
            column-gap: 12px;
            flex-wrap: wrap;

            div {
                min-width: 50px;
            }
        }

        .subtable_col {
            padding: 0;
            margin: 0;
            max-width: 60px;
        }
    }

    .account__info_estadistic_title {
        margin: 32px 0px 0;
        width: 100%;
    }

    .account__info_estadistic {
        display: flex;
        margin: 0px 12px 0;
        align-items: stretch;
        justify-content: center;
        gap: 32px;
        width: 100%;

        .card_info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            flex-grow: 1;
            border: 1px solid var(--gray100);
            text-align: center;
            border-radius: 4px;
            padding: 24px;

            label {
                font-weight: 500;
            }

            span {
                padding: 8px;
                font-weight: 600;
                background-color: var(--primary50);
                color: var(--primary700);
                background: var(--primary50);
                border-radius: 4px;
            }
        }

        .content-placeholder {
            flex-grow: 1;
            margin-bottom: 0;
        }
    }

    .search__content {
        gap: 16px;
        flex-wrap: nowrap !important;
    }

    .section__header {
        margin: auto !important;

        .k-section__title {
            margin: 0 !important;
        }
    }

    .search-form {
        position: relative;
        flex-shrink: 1;
        max-height: 40px;

        .order__search {
            position: relative;
            height: 100%;
            width: 100%;
            border: 1px solid var(--gray200);
            margin-bottom: 28px;
            border-radius: 4px;

            .ais-SearchBox-form {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;

                .ais-SearchBox-input {
                    width: 100%;
                    background: none;
                    height: 100%;
                    padding-left: 40px;
                    border: none;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.02em;
                    color: var(--gray500);
                    outline: none;

                    &::placeholder {
                        color: var(--gray300);
                    }
                }

                .ais-SearchBox-submit {
                    display: none;
                }

                .ais-SearchBox-reset {
                    margin-right: 5px;
                    border: none;
                    background: none;

                    &:hover {
                        cursor: pointer;
                    }

                    svg {
                        fill: var(--gray400);
                    }
                }

                .ais-SearchBox-loadingIndicator {
                    display: none;
                }
            }
        }
    }

    .row-content-table {
        width: 100%;
        margin-left: 12px;
        padding-right: 12px;

        div {
            flex-grow: 1;
        }
    }

    .react-bootstrap-daterangepicker-container {
        display: block;
        width: 100%;
    }

    .tap__panel_promotion {
        .form_inline {
            gap: 16px;
            width: 100%;
            flex-wrap: nowrap;

            input {
                flex-grow: 1;
            }
        }

        .actions {
            gap: 16px;
            flex-wrap: nowrap;

            button {
                min-width: 100px;

                @media (min-width: 1556px) {
                    min-width: 144px;
                }
            }
        }

        .row-container {
            gap: 16px;
        }

        button {
            min-width: 144px;
        }

        .badge_info {
            margin-right: auto;
        }

        .discount-content {
            padding: 0;
            display: flex;
            gap: 18px;
            flex-grow: 1;
        }

        legend {
            padding-bottom: 24px;
        }

        .form-check-label {
            display: flex;
            flex-wrap: nowrap;
            align-items: baseline;
            margin-bottom: 16px;

            button {
                min-width: 24px !important;
            }
        }

        .referral_selector {
            flex-grow: 1;
        }
    }

    .tap__panel_verification {
        .actions {
            display: flex;
            gap: 16px;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;

            button {
                min-width: 100px;
                flex-grow: 1;

                @media (min-width: 1556px) {
                    min-width: 144px;
                }
            }
        }

        .subrow__info {
            margin-bottom: 18px !important;
            display: flex;
            align-items: center;
            padding-left: 0;
            gap: 12px;
            flex-wrap: wrap;

            label {
                margin: 0;
                text-transform: unset;
                font-size: $text-base;
                line-height: 24px;
            }

            div {
                min-width: 50px;
            }
        }

        .k-section__title {
            margin-bottom: 24px;

            span {
                color: var(--gray400);
                font-weight: 400;
            }
        }

        .row-content-table {
            margin-bottom: 80px;
        }
    }

    .tap__panel_notes {
        label {
            text-transform: capitalize;
            font-size: $text-base;
            color: var(--gray800);
            font-weight: 500;
            margin-bottom: 16px;
        }

        p {
            color: var(--gray400);
            font-size: $text-sm;
        }

        .form-group {
            margin-bottom: 8px;
        }

        .actions {
            justify-content: end;
        }

        button {
            @media (min-width: 1556px) {
                width: 250px;
            }
        }
    }

    .tap__panel_activity_logs {
        .row-content-table {
            padding-right: 24px;
        }

        span {
            color: var(--gray400);
        }
    }

    .order_history__filters {
        padding: 0;
        padding-right: 16px;
        margin-right: 16px;
        max-width: 250px;
        border-right: 1px solid var(--gray100);

        @media (min-width: 1556px) {
            max-width: 315px;
        }

        .filters__options {
            padding: 0;
            border-right: none;

            .filters__subtitle {
                padding: 0;
            }
        }

        .react-bootstrap-daterangepicker-container {
            display: block;
            width: initial;
        }
    }

    .order_history__table {
        padding-right: 0;
    }

    .order_history__search_bar {
        padding-left: 0;
    }
}

.costumers__table_row_primary {
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
}

.costumers__table_row_secondary {
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--gray400);
}
