.message {
    border-radius: 6px;

    &.success {
        background: var(--primary50);
        border: solid var(--primary500);
        color: var(--primary500);
        border-width: 0 0 0 6px;
    }

    &.error {
        background: var(--red50);
        border: solid var(--red500);
        color: var(--red500);
        border-width: 0 0 0 6px;
    }

    &.info {
        background: #e9e9ff;
        border: solid #696cff;
        border-width: 0 0 0 6px;
        color: #696cff;
    }

    &.warning {
        background: #fff2e2;
        border: solid #cc8925;
        border-width: 0 0 0 6px;
        color: #cc8925;
    }

    .message_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 1.75rem;

        .close_button {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background: transparent;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
            cursor: pointer;
            border: none;

            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }
        }
    }
}
