.kanban__content {
    align-self: start;
    display: grid;
    padding: 0px 24px 24px;
    background-color: var(--white);

    @media (min-width: 1556px) {
        padding: 0px 32px 32px;
    }

    .k-portlet__head-toolbar {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px !important;

        @media (min-width: 768px) {
            flex-wrap: nowrap;
        }

        .btn-group {
            height: 40px;

            button {
                padding: 10px 16px;
                min-width: 155px;

                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: space-between;
                border-right: none;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;

                &:hover {
                    cursor: pointer;
                    border: 1px solid var(--gray600) !important;
                }
            }

            div {
                min-width: 155px;
            }

            .dropdown-item {
                &:hover {
                    border: unset !important;
                }
            }
        }

        .k-input-icon {
            display: flex;
            margin: 0;
        }
    }

    .accent-info {
        color: var(--blue200) !important;
        background-color: var(--blue50) !important;
    }

    .accent-success {
        color: var(--green200) !important;
        background-color: var(--green50) !important;
    }

    .accent-error {
        color: var(--red200) !important;
        background-color: var(--red50) !important;
    }

    .kanban__subtitle {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: $text-base;
        line-height: 24px;
        letter-spacing: -0.02em;
    }

    .filters__selectors {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;

        .badge__selected {
            display: flex;
            align-items: center;
            gap: 12px;
            max-height: 32px;
            padding: 6px 12px;
            background-color: var(--primary50);
            border: 1px solid var(--primary100);
            font-weight: 400;
            font-size: $text-sm;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: var(--gray800);
            border-radius: 100px;

            button {
                cursor: pointer;
                border: none;
                background-color: transparent;
                padding: 0;

                span,
                i {
                    color: var(--primary600) !important;
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .filters__selected_empty {
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.02em;
            padding: 6px 0;
            color: var(--gray300);
        }
    }

    .kanban__search {
        position: relative;
        height: 40px;
        width: 100%;
        border: 1px solid var(--gray200);
        border-radius: 4px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;

        .ais-SearchBox-form {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;

            .ais-SearchBox-input {
                width: 100%;
                background: none;
                height: 100%;
                padding-left: 40px;
                border: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.02em;
                color: var(--gray500);
                outline: none;

                &::placeholder {
                    color: var(--gray300);
                }
            }

            .ais-SearchBox-submit {
                display: none;
            }

            .ais-SearchBox-reset {
                margin-right: 5px;
                border: none;
                background: none;

                &:hover {
                    cursor: pointer;
                }

                svg {
                    fill: var(--gray400);
                }
            }

            .ais-SearchBox-loadingIndicator {
                display: none;
            }
        }

        &:hover,
        &:focus-visible {
            border: 1px solid var(--gray800);
        }
    }

    .kanban__header {
        position: sticky;
        top: 100px;
        z-index: 2;
        background-color: var(--white);
        padding-top: 24px;

        @media (min-width: 1024px) {
            top: 176px !important;
        }

        .kanban__subtitle {
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 0;
        }
    }

    .kanban__applied_filters {
        display: flex;
        min-height: 84vh;
        height: auto;
        gap: 24px;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            height: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray200);
            border-radius: 8px;
        }

        & > div {
            min-width: 240px;
            flex-grow: 1;
            margin-bottom: 0.5rem;
            padding: 16px 8px 8px;
            background-color: var(--gray50);
            border-radius: 8px;
            border: 1px solid var(--gray100);

            h3 {
                display: flex;
                align-items: center;
                margin-bottom: 18px;

                svg {
                    height: 20px;
                    width: 20px;
                }

                .kanban_amount {
                    margin-left: 8px;
                    font-weight: 500;
                    font-size: $text-xs;
                    line-height: 14px;
                    letter-spacing: -0.02em;
                    color: var(--gray600);
                }
            }

            .kanban_container {
                margin-bottom: 50px;
                display: flex;
                flex-direction: column;
                height: auto;
                gap: 12px;

                .btn-card-dropdown {
                    position: absolute;
                    right: 45px;
                    top: 10px;
                }

                .kanban_card__bt_group {
                    &:hover,
                    &:active,
                    &:focus {
                        .moreButton::after {
                            opacity: 0;
                        }

                        box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1),
                            0 10px 10px -5px rgba(0, 0, 0, 0.04);
                    }
                }

                .kanban_card {
                    width: 100%;
                    position: relative !important;
                    cursor: pointer;
                    background-color: var(--white);
                    border: 1px solid var(--gray100);
                    border-left: 4px solid var(--gray400);
                    border-radius: 4px;
                    padding: 12px;
                    font-weight: 400;
                    font-size: $text-xs;
                    line-height: 16px;
                    letter-spacing: -0.02em;
                    color: var(--gray400);
                    transition: all ease 0.3s;
                    box-sizing: content-box;

                    .kanban_card__header {
                        margin-bottom: 6px;
                        min-height: 20px;

                        h4 {
                            color: var(--gray700);
                            text-align: left;
                            font-weight: 500;
                            font-size: $text-sm !important;
                            line-height: 20px;
                            margin: 0;
                        }
                    }

                    .kanban_card__city {
                        margin-bottom: 4px;
                        display: flex;
                        align-items: center;
                    }

                    .kanban_card__datetime {
                        border-bottom: 1px solid var(--lines);
                        margin-bottom: 12px;
                        text-align: left;
                        padding-bottom: 12px;
                        line-height: 16px;
                    }

                    .kanban_card__order_info {
                        display: flex;
                        place-content: space-between;
                        align-items: center;

                        .kanban_card__order_price {
                            margin-bottom: 0;
                            font-weight: 600;
                            font-size: $text-sm;
                            line-height: 20px;
                            letter-spacing: -0.02em;
                            color: var(--gray700);
                        }
                    }
                }

                .moreButton {
                    position: absolute;
                    padding: 0;
                    align-items: center;
                    border: none;
                    text-align: center;
                    border-radius: 4px;
                    width: 35px;
                    height: 24px;

                    i {
                        margin-left: 5px;
                        margin-top: 1px;
                        font-size: 18px;
                        color: var(--gray400);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--white);
                        z-index: 1;
                        opacity: 1;
                        transition: all ease 0.3s;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: var(--actives);

                        &::after {
                            opacity: 0;
                        }
                    }

                    /* TODO: SET MORE BUTTON TO ACTIVATE AND DEACTIVATE */
                }

                .more-options {
                    min-width: 128px;
                    background-color: var(--white);
                    border: 1px solid var(--gray100) !important;
                    box-shadow: 2px 2px 8px rgba(175, 181, 199, 0.25);
                    transform: translate3d(-92px, 30px, 0px) !important;

                    .dropdown-header {
                        cursor: auto;
                        font-size: $text-small;
                        line-height: 14px;
                        color: var(--gray400);
                        letter-spacing: 0.01em;
                        font-weight: 500;
                        text-transform: uppercase;
                    }

                    .dropdown-item {
                        cursor: pointer;
                        font-weight: 500;
                        font-size: $text-sm;
                        line-height: 20px;
                        letter-spacing: -0.02em;
                        color: var(--gray600);

                        &:hover {
                            color: var(--primary500);
                        }
                    }

                    & > * {
                        padding: 6px 12px;
                    }
                }

                .kanban_card_border-info {
                    border-left: 4px solid var(--blue200);
                }

                .kanban_card_border-success {
                    border-left: 4px solid var(--green200);
                }

                .kanban_card_border-error {
                    border-left: 4px solid var(--red200);
                }
            }
        }
    }

    .data__presentation_options {
        display: flex;
        align-items: center;
        gap: 6px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid var(--gray100);
        padding: 4px;

        .btn__option {
            padding: 0;
            height: 24px;
            width: 24px;
            border-radius: 4px;
            background-color: var(--white);
            transition: 0.3s ease all;

            svg {
                path {
                    stroke: var(--gray300);
                }
            }

            &:hover {
                svg {
                    path {
                        stroke: var(--primary600);
                    }
                }

                background-color: var(--primary50);
            }

            &_active {
                svg {
                    path {
                        stroke: var(--primary600);
                    }
                }
                background-color: var(--primary50);
            }
        }
    }

    .badge__status {
        display: unset;
    }
}
