.note-popover .popover-content,
.panel-heading.note-toolbar {
    padding: 0 0 5px 5px;
    margin: 0;
    background: white;
    border: 1px solid #efebeb;
}

.note-editor.note-frame {
    border: 1px solid #ebedf2;
}

.note-popover .popover-content,
.panel-heading.note-toolbar {
    border: 0 solid #efebeb !important;
    border-bottom: 1px solid #efebeb !important;
}
