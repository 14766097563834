.k-header__topbar-wrapper--stores {
    $space: calc(1.75rem + 2px);

    .k-header__topbar-icon {
        background-color: transparent!important;
        height: 36px!important;
        width: 36px!important;
    }

    .dropdown-menu {
        width: 20rem;

        .form-group {
            cursor: default;
            margin-bottom: 1.5rem;
            padding-top: .5rem;
            padding-left: $space;
            padding-right: $space;
        }

        .dropdown-divider {
            border-top: 1px solid #f7f8fa;
            margin: .75rem 0 1.35rem 0;
        }

        .dropdown-item--no-stores {
            color: #adb5ae;
            padding: .5rem $space 1rem;
            pointer-events: none;
        }

        .scrollbar-container {
            margin-bottom: .5rem;
        }

        .k-nav {
            // max-height: 190px;
            padding: 0;
        }
    }

    @include k-desktop {
        .k-header__topbar-icon {
            margin-right: .5rem;
        }
    }

    .k-nav {
        max-height: 360px !important;
        padding-right: 2px;
        margin-right: 2px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray300);
            border-radius: 8px;
        }
    }
}

.k-header__topbar-wrapper--deployment-status {
    $space: calc(1.75rem + 2px);

    .k-header__topbar-icon {
        background-color: transparent!important;
        height: 36px!important;
        width: 36px!important;
    }

    .spinner {
      color: #CCA439 !important;
    }

    .text-metalic {
       color: var(--gray300) !important;
    }

    .tag__status {
        color: #3e7a18;

        &-building {
            color: #CCA439;
        }

        &-error {
            color: #F15857;
        }
    }

    .dropdown-menu {
        width: 11rem;
        padding: 10px 8px;

        button {
            display: flex;
            align-items: center;
            column-gap: 8px;
            width: 100%;
        }

        span {
            font-weight: 500;
        }
        
        button + button {
            margin-top: 8px;
        }

        .status-last-update {
            display: flex;
            column-gap: 4px;
            height: 20px;
            margin-top: 8px;
           
            > span {
                color: #727A94;
            }
        }

    }

    @include k-desktop {
        .k-header__topbar-icon {
            margin-right: .5rem;
        }
    }
}
