@media (min-width: 1025px) {
    .k-header {
        background-color: var(--white);
        box-shadow: 2px 2px 2px 0 rgba(105, 63, 63, 0.05);
    }
    .k-header .k-header-menu .k-menu__nav > .k-menu__item > .k-menu__link {
        border-radius: 4px;
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item
        > .k-menu__link
        .k-menu__link-text {
        color: #fefefe;
        font-weight: 500;
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item
        > .k-menu__link
        .k-menu__hor-arrow {
        color: #c8cde8;
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item
        > .k-menu__link
        .k-menu__hor-icon {
        color: #c8cde8;
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--here
        > .k-menu__link,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--active
        > .k-menu__link,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item:hover
        > .k-menu__link {
        background-color: #e9f1e8;
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__link-text,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-text,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item:hover
        > .k-menu__link
        .k-menu__link-text {
        color: var(--white);
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__hor-arrow,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__hor-arrow,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item:hover
        > .k-menu__link
        .k-menu__hor-arrow {
        color: var(--white);
    }
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__hor-icon,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__hor-icon,
    .k-header
        .k-header-menu
        .k-menu__nav
        > .k-menu__item:hover
        > .k-menu__link
        .k-menu__hor-icon {
        color: var(--white);
    }
}
.k-aside-secondary--enabled .k-header .k-header__topbar {
    padding-right: 0;
}
.k-header .k-header__topbar .k-header__topbar-item .k-header__topbar-icon i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--gray600);
}
.k-header
    .k-header__topbar
    .k-header__topbar-item.k-header__topbar-item--user
    .k-header__topbar-user
    .k-header__topbar-welcome {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--gray800);
}
.k-header
    .k-header__topbar
    .k-header__topbar-item.k-header__topbar-item--user
    .k-header__topbar-user
    .k-header__topbar-username {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--gray800);
}
.k-header__topbar-welcome-store {
    display: flex;
    flex-direction: column !important;
    padding: 0 12px;
}
.k-header__topbar-tag {
    font-size: 0.86rem;
    line-height: 16px;
    letter-spacing: -2%;
    color: var(--gray400);
}
.k-header__topbar-tag-store {
    font-size: 0.95rem;
    color: var(--gray800);
    line-height: 20px;
    font-weight: 500;
}
.k-menu__ver-arrow-store {
    padding: 0 16px 0 8px;
    font-size: 9px;
}
/* .k-header
    .k-header__topbar
    .k-header__topbar-item:hover.k-header__topbar-item--user
    .k-header__topbar-user
    .k-header__topbar-username {
    color: #424a66;
} */
.k-header
    .k-header__topbar
    .k-header__topbar-item.k-header__topbar-item--quick-panel
    .k-header__topbar-icon
    i {
    color: var(--white);
}
.k-header .k-header__topbar .k-header__topbar-item:hover .k-header__topbar-icon,
.k-header .k-header__topbar .k-header__topbar-item.show .k-header__topbar-icon {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: var(--actives);
}
.k-header
    .k-header__topbar
    .k-header__topbar-item:hover
    .k-header__topbar-icon
    i,
.k-header
    .k-header__topbar
    .k-header__topbar-item.show
    .k-header__topbar-icon
    i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #242938;
}
.k-header
    .k-header__topbar
    .k-header__topbar-item:hover.k-header__topbar-item--user
    .k-header__topbar-user,
.k-header
    .k-header__topbar
    .k-header__topbar-item.show.k-header__topbar-item--user
    .k-header__topbar-user {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: var(--actives);

    .k-header__topbar-icon-store {
        background-color: var(--white) !important;
    }
}

.k-aside-secondary--enabled
    .k-header
    .k-header__topbar
    .k-header__topbar-item:hover.k-header__topbar-item--quick-panel,
.k-aside-secondary--enabled
    .k-header
    .k-header__topbar
    .k-header__topbar-item.show.k-header__topbar-item--quick-panel {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: var(--white);
}
.k-aside-secondary--enabled
    .k-header
    .k-header__topbar
    .k-header__topbar-item:hover.k-header__topbar-item--quick-panel
    .k-header__topbar-icon,
.k-aside-secondary--enabled
    .k-header
    .k-header__topbar
    .k-header__topbar-item.show.k-header__topbar-item--quick-panel
    .k-header__topbar-icon {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: transparent !important;
}
.k-aside-secondary--enabled
    .k-header
    .k-header__topbar
    .k-header__topbar-item:hover.k-header__topbar-item--quick-panel
    .k-header__topbar-icon
    i,
.k-aside-secondary--enabled
    .k-header
    .k-header__topbar
    .k-header__topbar-item.show.k-header__topbar-item--quick-panel
    .k-header__topbar-icon
    i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--white);
}
.k-header
    .k-header__topbar
    .k-header__topbar-item.k-header__topbar-item--search
    .k-input-icon
    .k-input-icon__icon
    i {
    color: #adb5ae;
}
@media (max-width: 1024px) {
    .k-header__topbar {
        background-color: var(--white) fff;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .k-header__topbar--mobile-on .k-header__topbar {
        -webkit-box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
        box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
        border-top: 1px solid #ebedf2;
    }
}
