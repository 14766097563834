.checkbox {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
        margin: 0;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.5rem;
        letter-spacing: -0.02em;
        color: var(--gray500);
        cursor: pointer;
    }

    button {
        cursor: pointer;
    }

    .unchecked {
        margin-right: 0.5rem;
        padding: 0;
        border: none;
        background-color: var(--white);
        transition: all 0.3s ease;
    }

    .checked {
        margin-right: 0.5rem;
        padding: 0;
        border: none;
        background-color: var(--white);
        transition: all 0.3s ease;

        svg {
            path {
                fill: var(--primary500);
            }
        }
    }

    .disabled {
        svg {
            path {
                fill: var(--gray200);
            }
        }

        &:hover {
            cursor: unset;
        }
    }
}
