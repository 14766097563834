.business__settings {
    @extend .customers__details;

    .k-portlet__body {
        padding: 24px 12px !important;
    }

    .row-label {
        padding-left: 12px;
        align-self: center;
        text-align: left !important;
        max-width: 200px;
    }

    .k-portlet__head {
        border-bottom: 1px solid #edeff5;
    }

    .k-section.section-separator {
        width: calc(100% - 24px) !important;
        height: 1px !important;
        background-color: #edeff5;
        margin: 32px 12px !important;
    }

    .list-collapse {
        cursor: pointer;
        padding: 0 0 30px 0;

        :hover {
            color: var(--primary500);
        }

        .k-section__title {
            margin: 0;
        }
    }

    .list-expanded {
        .k-section__title {
            color: var(--primary500) !important;
        }
    }

    .notification__content {
        position: relative;

        .btn__variables_template{
            position: absolute;
            top: 50px;
            right: 40px;

        }       
    }
}

.notification__tp_body {
    display: grid;
    max-height: 640px;
    margin-right: 2px;
    overflow-y: auto;
    gap: 20px;
    padding: 40px;

    @media (min-width: 1024px) {
       grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &::-webkit-scrollbar {
       width: 5px;
       height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--gray200);
        border-radius: 8px;
    }

    h5 {
        margin-bottom: 16px;
    }

    ul {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        padding: 0;
    }

    .notification__variable {
        display: flex;
        align-items: center;
        column-gap: 16px;

        .notification__copy {
            padding: 0;
            position: relative;
            top: -2px;
            border: none;
            background: transparent;

               svg {
                    path {
                        stroke: var(--gray300);
                    }
                }

            &:hover,
            &:active,
            &:focus {
                background: transparent !important;

                svg {
                    path {
                        stroke: var(--primary300);
                    }
                }
            }
        }
    }
}
