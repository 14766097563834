.btn.dropdown-toggle {
    color: inherit !important;

    &::after {
        @extend .fas;

        content: "\f078" !important;
        line-height: 0 !important;
        margin-right: 0.6rem;
        vertical-align: baseline;
    }
}

.k-switch.k-switch--icon input:empty ~ span::after {
    @extend .fas;

    content: "\f00c" !important;
    line-height: 2.1 !important;
}
