@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    // stylelint-disable-next-line property-blacklist
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-sm {
    width: 1.3rem;
    height: 1.3rem;
    border-width: .21em;
}

// button,
// .form-control {
//     .spinner {
//         vertical-align: text-top;
//     }
// }

button:not(.btn-icon) {
    .spinner {
        box-sizing: border-box;
        margin-right: .5rem;
    }
}
