.nestable {
    .nestable-item,
    .nestable-list {
        margin-top: -1px;
    }

    > .nestable-list {
        padding: 0 !important;
    }

    .nestable-list {
        padding: 0 0 0 2.1rem;
    }

    .nestable-item-handler {
        @extend .px-3, .text-muted;

        cursor: move;
    }

    [class*="fa-caret-"] {
        @extend .px-2, .py-1;

        cursor: pointer;
    }

    .nestable-item {
        &::before {
            @extend .rounded;

            border-color: var(--primary500);
            background: transparent;
            z-index: 1;
        }
    }

    .nestable-item-name {
        @extend .d-flex, .align-items-center, .py-2, .pr-3, .border, .rounded;

        background: white;
        // background: #fefefe;
        // box-shadow: 2px 2px 2px 0 rgba(105, 63, 63, 0.05);
    }

    .nestable-tools {
        @extend .ml-auto;

        white-space: nowrap;
    }
}
