.k-portlet {
	&.k-portlet--sticky {
		.k-portlet__head {
			@at-root {
				.k-header__topbar--mobile-on & {
					top: 110px;
				}
			}

			background-color: var(--white);
			position: sticky;
			top: 60px;
			transition: top 0.3s ease;
			z-index: 90;
		}
	}

	@include k-desktop {
		&.k-portlet--sticky {
			.k-portlet__head {
				top: 75px !important;
			}
		}
	}
}
