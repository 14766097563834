.react-bootstrap-table-pagination-list {
    display: flex;
    justify-content: flex-end;
}

.react-bootstrap-table {
    border-radius: 4px !important;

    .badge__status {
        display: inherit;
    }
}

table {
    border: 1px solid var(--gray100) !important;
}

.table-customizations {
   border: 1px solid #F0F2F7 !important;

   th {
    color: #727A94;
   }

   span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.02px;
    color: #909AAD;
   }

   strong {
    line-height: 20px;
    font-weight: 400 !important;
    color: #1A2333 !important;
   }

   .table-cell {
       width: 80px;
   }

   .table-cell-note {
        width: 360px;

        @media (max-width: 1300px) {
            max-width: 230px;
        }
   }
}

.table-borderles-top {
   border-top: 0 !important;
}

table td {
    overflow-wrap: break-word;
    word-break: break-all;
}

.footer-bg {
    background-color: var(--white);

    th {
        font-weight: normal;
    }
}

.table-validate {
    border: 1px solid var(--red200);
}

.w-1 {
    white-space: nowrap;
    width: 1px;
}

.react-bootstrap-table th[data-row-selection] {
    width: 40px;
    cursor: pointer;
}

.table-row--selected {
    background-color: var(--gray50);
}

.selection-cell {
    cursor: pointer;

    .k-checkbox span {
        pointer-events: none;
    }
}

.filter__header {
    font-weight: 500;
    font-size: $text-base;
}

.filters__btn_link_clear {
    font-size: $text-base;
    color: var(--primary600);

    &:hover {
        text-decoration: none;
    }
}

.react-bootstrap-table-pagination {
    margin-top: 16px;
}

.filter-ballon {
    display: flex;
    align-items: center;
    gap: 12px;
    max-height: 32px;
    padding: 6px 12px;
    background-color: var(--primary50);
    border: 1px solid var(--primary100);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--gray800);
    border-radius: 100px;

    overflow: hidden;
    i {
        color: var(--primary600);
    }
}

.filter.number-filter {
    display: flex;
    flex-direction: column;
}

.filter-label {
    width: 100%;
}

.selection-cell-header {
    align-items: center;
}

.more-actions {
    border: none;
    display: flex;
    justify-content: center;

    button {
        border: none;
        text-align: center;
        border-radius: 4px;
        background-color: transparent !important;
        display: flex;
        padding: 8px 4px;
        width: 24px;
        height: 24px;

        i {
            font-size: 14px;
            color: var(--gray400);
        }

        &:hover,
        &:focus {
            background-color: transparent !important;
            i {
                color: var(--primary500);
            }
        }
    }

    .dropdown-menu {
        min-width: 128px !important;
        border-radius: 8px;
        border: 1px solid var(--gray100) !important;
        box-shadow: 2px 2px 8px rgba(175, 181, 199, 0.25);
        right: 0 !important;
        font-weight: 500;

        .dropdown-header {
            text-align: left !important;
            font-size: $text-small;
            letter-spacing: 0.01em;
            line-height: 14px !important;
            padding: 9px 20px;
            text-transform: uppercase;
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 28px;
            padding: 6px 20px;
            font-weight: 500;
            font-size: $text-sm;
            line-height: 20px !important;
            color: var(--gray600);
            letter-spacing: -0.02em;

            &:hover {
                color: var(--primary500);
            }
        }
    }

    &:focus-visible {
        outline: none;
    }
}

.btn-group:hover {
    border: none !important;
}

.load_more__button {
    justify-self: right;
}
