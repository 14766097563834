.apps__install {

    .app__info_message {
        font-size: 14px;
        padding: 10px 12px;
        background-color: var(--blue50);
        margin-bottom: 16px;
        border-radius: 4px;
        width: fit-content;

        p {
            margin-bottom: 0;
            color: var(--blue300);

            span {
                margin-right: 8px;
            }
        }
    }

    .k-section__title {
        margin-bottom: 24px;
    }

    .apps__select-category {
        .react-select__menu {
            height: fit-content
        }

        width: 230px;
        > div {
            height: 41.45px;
        }
    }

    .app__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(297px, 1fr));
        gap: 24px;
    }

    .apps_card {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-self: start;
        justify-content: flex-start;
        background-color: var(--white);
        border: 1px solid var(--gray100);
        border-left: 4px solid var(--gray400);
        border-radius: 4px;
        padding: 12px;
        transition: all ease 0.3s;

        .apps_card__header {
            display: flex;
            align-items: center;
            column-gap: 10px;

            figure {
                margin-bottom: 0;
            }

            h4 {
                color: var(--gray700);
                text-align: left;
                font-weight: 500;
                font-size: $text-sm !important;
                line-height: 20px;
                margin: 0;
            }
        }

        .apps_card__description {
            margin-top: 8px;
            font-weight: 400;
            font-size: $text-xs;
            line-height: 16px;
            letter-spacing: -0.02em;
           // margin-left: 46px;
            color: var(--gray400);
        }

        .k-separator {
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .app__btn_action {
            padding: 6px 16px;
            height: 32px;
            min-width: 60px !important;
            margin-left: 0 !important;
            width: fit-content;

            .spinner-sm {
                width: 14px;
                height: 14px;
            }
        }
    }

    .apps_card_border-install {
        border-left: 4px solid var(--primary500);
    }

    .apps_card_border-unistall {
        border-left: 4px solid var(--gray400);
    }

    .app__empty_results {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;

        .empty_results_body {
            max-width: 300px;
            text-align: center;
            
            h5, p {
                font-size: 14px;
            }

            h5 {
                margin-top: 12px;
                font-weight: 500;
            }
        }
    }
}

