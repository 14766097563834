.customer__details_tabs {
    height: 100%;

    .nav-item {
       
        .active {
            background: var(--primary50);

            &:hover, &:focus-within {
                background: var(--primary50);
            }
        }
    }

    .nav-tabs {
        padding: 0 24px;
        margin-bottom: 0;
    }

    .tab-content {
        padding: 24px;
    }

    &_title {
       display: flex;
       flex-direction: column;
       row-gap: 5px;

    }

    .tabs_title {
        font-size: 12px !important;
        text-align: center;
    }
}
