.css-2b097c-container {
    position: relative;
    &.is-invalid {
        [class$="-control"],
        [class$="-control"][class*="1pahdxg"] {
            border-color: var(--red100) !important;
        }
    }

    &.form-control {
        border: 0;
        border-radius: 0;
        padding: 0;
        height: auto;
    }

    [class$="-control"] {
        border-color: var(--gray200);
        // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: none;
        border-radius: 0.25rem;
        padding-left: 1rem;
        min-height: calc(2.8rem);
        box-shadow: none;

        &[class*="1pahdxg"] {
            border-color: var(--gray200) !important;
        }

        &:hover {
            border-color: var(--gray500);
            //color: var(--primary500) !important;
        }

        .css-1hwfws3 {
            padding: 0;

            .css-1wa3eu0-placeholder {
                color: var(--gray300);
                font-weight: 400;
                margin: 0;
                line-height: normal;
            }

            .css-b8ldur-Input {
                color: var(--gray600);
                margin: 0;
                padding: 0;
            }

            .css-1uccc91-singleValue {
                color: var(--gray800);
                font-weight: 400;
                margin: 0;
                line-height: normal;
            }
        }

        .css-1hb7zxy-IndicatorsContainer {
            .css-1okebmr-indicatorSeparator {
                display: none;
            }
        }
    }

    .css-26l3qy-menu {
        width: 100%;
        height: initial;
        padding-right: 3px;
        z-index: 95;

        > div {
            height: 100%;
            max-height: 200px;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--gray200);
                border-radius: 8px;
            }
        }
    }

    .react-select__menu {
        top: initial;
        margin-top: 5px;
        z-index: 95;
        border-radius: 2px;
        font-weight: 400;
        color: var(--gray700);
        background-color: var(--white) !important;

        .react-select__option {
            cursor: pointer;
            background-color: var(--white) !important;

            &:hover {
                background-color: var(--gray50) !important;
            }
            &--is-selected {
                color: var(--gray700);
                background-color: var(--primary50) !important;
                &:hover {
                    background-color: var(--primary100) !important;
                    color: var(--gray700);
                }
            }
        }

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray200);
            border-radius: 8px;
        }
    }

    //ballon when multi select
    .css-1rhbuit-multiValue {
        color: var(--gray400);
        background-color: var(--primary50);
        margin-right: 4px;
        border-radius: 4px;

        .css-12jo7m5 {
            color: var(--primary800);
            font-weight: 500;
            font-size: 13px;
            padding: 4px 8px;
            padding-left: 13px;
        }

        .css-xb97g8:hover {
            background-color: rgba(var(--primary50), 0.3);
            color: var(--primary800);
            cursor: pointer;
        }
    }

    .css-2o5izw {
        border: 1px solid var(--lines) !important;
        transition: border-color 0.15s ease-in-out;
        box-shadow: none !important;
        border-radius: 0.25rem;
    }

    .css-vj8t7z {
        border: 1px solid var(--lines) !important;
        transition: border-color 0.15s ease-in-out;
        border-radius: 0.25rem;
    }

    //separator between input and toogle arrow
    span.css-d8oujb {
        display: none;
    }

    //options's container
    .css-15k3avv {
        margin-top: 3px;

        //options
        .css-11unzgr {
            > div {
                background-color: var(--primary50);
                // color: black;
            }
            > div:hover {
                background-color: var(--primary50);
                // color: black;
            }
            > div:active {
                background-color: var(--primary100);
                color: var(--gray700);
            }
        }
    }

    &.select-inline-block {
        display: inline-block;
        min-width: 110px;
    }

    &.dashboard-select--width {
        min-width: 170px;
    }
}
