.dropZone {
    margin-bottom: 20px;

    &.dropZone-invalid {
        section > div {
            // @extend .border-danger;

            border: 2px dashed #d3dbde;
        }
    }

    section > div {
        // @extend .rounded, .text-center;

        border: 2px dashed #d3dbde;
        padding: 17px;
        cursor: pointer;
    }

    [tabindex]:focus {
        outline: 0;
    }

    .dropZone-msg {
        // @extend .mb-0;

        color: inherit;
    }
}

.product-img-placeholder {
    // @extend .bg-light, .rounded, .d-flex, .justify-content-center, .align-items-center;

    margin-right: 17px;
    // margin-bottom: 17px;
    width: 112px;
    height: 112px;

    // &::before {
    //   // @extend .spinner-border, .text-white;

    //   content: '';
    // }
}

.product-img {
    // @extend .rounded;

    // @extend .bg-light, .rounded, .d-flex, .justify-content-center, .align-items-center;

    position: relative;
    margin-right: 17px;
    // margin-bottom: 17px;

    > div {
        &:hover {
            i {
                display: block;
            }
        }
    }

    i {
        // @extend .rounded-circle, .text-light, .text-center, .shadow;

        font-size: 16px;
        line-height: 1.6;
        cursor: pointer;
        position: absolute;
        width: 25px;
        height: 25px;
        top: -8px;
        right: -8px;
        background-color: var(--primary500);
        display: none;
    }

    img {
        // @extend .rounded, .bg-light;

        object-fit: contain;
        width: 112px;
        height: 112px;
    }

    .form-group {
        // @extend .mb-0, .mt-3;
    }

    label {
        // @extend .mb-0;
    }
}
