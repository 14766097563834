.day__body {
   align-items: center;
   padding: 10px 10px 20px;
   gap: 24px;

    @media (max-width: 1024px){
        gap: 10px;
    }

   .day__column_checkbox {
        line-height: 15px !important;
        font-size: $text-sm;
        display: flex;
        align-items: center;
        min-width: 160px;
        span {
            margin-right: 10px !important;
        }
    }

    label {
        margin-bottom: 0;
    }

    .day__operation_hours {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: baseline;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 380px;
        gap: 10px;

        .form-group {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            margin-bottom: 0;
            max-width: 180px;
            row-gap: 3px;

            span {
                color: var(--red400);
                font-size: 10px;
            }

            label {
               align-self:flex-start;
               font-size: 10px !important;
            }
        }

        input[type="time"]{
            padding: 4px;
            border-radius: 4px;
            border: 1px solid var(--gray200);

            &:hover, &:focus-within {
                border: 1px solid var(--gray500);
            }
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
            opacity: 0.50;
            color: var(--green200);
        }

        input[type=time]::-webkit-datetime-edit-fields-wrapper {
            color: var(--gray400);
        }

        .day__input_invalid {
            border: 1px solid var(--red400) !important;
        }
    }

    .text-danger {
        padding: 12px 0;
    }

  
}