// #gjs * {
//     outline: 1px solid red;
//     &::after,
//     &::before {
//         outline: 1px solid red;
//     }
// }

$primaryColor: white;
$secondaryColor: #515872;
$tertiaryColor: #629c1c;
$quaternaryColor: #629c1c;
// Inputs
$mainDkColor: white;
// Form Group
$mainDklColor: white;
$leftWidth: #{260px};
// Inputs labels
$mainLhColor: #161616;
// Inputs Carets
$mainLhlColor: black;
$colorYell: #1dc9b7;
$colorWarn: #1dc9b7;
$mainFont: Poppins, sans-serif;
$fontSizeS: 1rem;

@import "~grapesjs/src/styles/scss/main.scss";
@import "~grapick/src/styles/main.scss";

@font-face {
    font-family: "OldFontAwesome";
    src: url(./old-fontawesome.woff2);
    font-weight: normal;
    font-style: normal;
}

.gjs-btn {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

.gjs-btn-primary {
    color: var(--white);
    background-color: #629c1c;
    border-color: #629c1c;

    &:hover {
        background-color: var(--primary500);
        border-color: var(--primary500);
    }

    &:active {
        background-color: var(--primary500);
        border-color: var(--primary500);
    }
}

// S3 Plugins --------------------------------------------------------------
.gjs-gc-loader {
    background-color: rgba(0, 0, 0, 0.03);
    bottom: 0;
    display: flex;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1040;

    &::before {
        box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
        // border: 1px solid #f3f3f3;
        background-color: white;
        border-radius: 4px;
        content: attr(data-message) " ";
        height: 100px;
        min-width: 100px;
        margin: auto;
        padding: 17px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    &::after {
        animation: spin 0.8s linear infinite;
        border-radius: 50%;
        border: 4px solid #629c1c;
        border-top-color: transparent;
        content: "";
        top: calc(50% - 24px);
        left: calc(50% - 12px);
        position: fixed;
        width: 24px;
        height: 24px;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
// ---------------------------------------------------------------------------

.gjs-bg-primary {
    background-color: #629c1c;
}

.gjs-rounded {
    border-radius: 0.25rem;
}

button.gjs-btn-s3.gjs-btn-s3 {
    margin-bottom: 1.5rem;
    margin-top: 0;
}

.tui-image-editor__apply-btn {
    @extend .gjs-btn, .gjs-btn-primary;

    background-color: #629c1c !important;
    margin-right: 0 !important;
}

.tui-image-editor-container {
    background-color: white !important;

    .tui-image-editor-submenu-style {
        background-color: #343a4d !important;
    }

    .tui-image-editor-checkbox-wrap.fixed-width {
        width: 105px !important;
    }

    .tui-image-editor-range-wrap.tui-image-editor-newline.short label {
        color: #e3e5f2 !important;
    }

    .tui-image-editor-virtual-range-pointer {
        background-color: var(--white) !important;
    }

    .tui-image-editor-disabled .tui-image-editor-virtual-range-bar {
        background-color: #666 !important;
    }

    .tui-image-editor-disabled .tui-image-editor-virtual-range-subbar {
        background-color: #d1d1d1 !important;
    }

    .tui-image-editor-main-container {
        bottom: 50px !important;
        background-color: white !important;
    }

    // .tui-image-editor-wrap .tui-image-editor-size-wrap {
    //     display: block!important;

    //     .tui-image-editor-align-wrap {
    //         display: block;
    //         height: 100%;
    //     }
    // }

    .tui-image-editor-submenu {
        height: auto !important;
    }

    &.bottom {
        @extend .gjs-rounded;

        margin-bottom: 0.5rem;

        .tui-image-editor-submenu > div {
            padding-top: 24px;
        }
    }

    .tui-image-editor-controls {
        height: auto !important;
    }

    .tui-image-editor-menu {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }

    // .tui-image-editor-item {
    //     &:hover {
    //         color: var(--primary500)!important;
    //     }
    // }

    .tui-image-editor-controls {
        background-color: white !important;
    }
}

.gjs-toolbar-item {
    font-size: 1rem;

    svg {
        width: 15px;
    }
}

.gjs-editor-cont ::-webkit-scrollbar-thumb {
    background-color: #e3e5f2;
}

.gjs-editor-cont ::-webkit-scrollbar {
    height: 8px;
}

.CodeMirror.cm-s-hopscotch.CodeMirror-wrap {
    // TO_CHECK
    @extend .gjs-rounded;

    background-color: #343a4d;
}

.CodeMirror-gutter.CodeMirror-linenumbers {
    background-color: #161616;
}

.gjs-cm-editor#gjs-cm-htmlmixed #gjs-cm-title,
.gjs-cm-editor#gjs-cm-css #gjs-cm-title {
    color: black;
}

button.gjs-btn-prim {
    @extend .gjs-btn, .gjs-btn-primary;

    margin: 1rem 0;
    display: block;
    margin-left: auto;
}

.gjs-btn-import {
    margin-top: 1.5rem !important;
}

.gjs-am-meta {
    width: calc(70% - 30px);
    word-break: break-word;
}

.gjs-am-highlight {
    // TO_CHECK
    @extend .gjs-rounded;

    background-color: #f7f8fa;
}

.gjs-am-preview-cont {
    // TO_CHECK
    @extend .gjs-rounded;
}

.gjs-am-name {
    color: #161616;
    font-weight: 400;
}

.gjs-field.gjs-am-add-field {
    input {
        height: 41px;
    }
}

.gjs-am-add-asset {
    display: flex;
    margin-bottom: 1rem;

    button {
        margin: 0;
        margin-left: 1rem;
        width: auto;
    }
}

.gjs-am-assets {
    height: 243px;
}

.grp-handler-close {
    margin-left: -5px;
    line-height: 0.6;
    font-size: 20px;

    &:hover {
        color: #fa7c66;
    }
}

.grp-handler-cp-wrap {
    margin-left: -6px;
}

// .gjs-field.gjs-field-range {
//     background-color: #f7f8fa !important;
// }

.gjs-clm-tag {
    color: white;
}

.gjs-traits-label {
    border-bottom: 1px solid #ebedf2;
    color: #161616;
}

.gjs-trt-trait .gjs-label {
    color: #161616;
}

.gjs-sm-field {
    &.gjs-sm-stack,
    &.gjs-sm-composite {
        // TO_CHECK
        @extend .gjs-rounded;

        border: 1px solid #ebedf2 !important;
        box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    }
}

.gjs-clm-sel-id {
    opacity: 1;
    font-weight: 500;
}

#gjs-sm-add {
    color: black !important;

    &:hover {
        color: var(--primary500) !important;
    }
}

.gjs-sm-label,
.gjs-label {
    font-weight: 400;
}

label {
    font-weight: 400;
    font-size: 14px;
}

.gjs-sm-sector {
    &.gjs-sm-open {
        border: 0;

        .gjs-sm-title {
            color: var(--primary500);
            background-color: #f7f8fa !important;
        }
    }

    .gjs-sm-title {
        font-weight: 400;
        border-bottom: 0;
        padding-bottom: 0.7rem !important;
        padding-top: 0.7rem !important;

        &:hover {
            color: var(--primary500);
            background-color: #f7f8fa !important;
        }
    }
}

.gjs-field {
    background-color: white;
    border: 1px solid #ebedf2;
    border-radius: 0.25rem;
    color: #495057;

    &.gjs-field-checkbox {
        @extend .gjs-bg-primary;

        input:checked + .gjs-chk-icon {
            border-color: white;
        }
    }

    // &:focus {
    //     border-color: #9aabff;
    // }
}

.gjs-trt-header,
.gjs-sm-header {
    color: #161616;
}

.gjs-block-label {
    font-family: Poppins, Arial, Helvetica, sans-serif;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    flex-direction: column;

    .gjs-block-label {
        height: auto;
        color: #161616;
    }
}

// .gjs-pn-views {
//     box-shadow: none;
// }

// .gjs-blocks-cs {
//     background-color: #F9F9FC;
// }

.gjs-block-category {
    &.gjs-block-category {
        border-bottom: 0;
    }
}

.gjs-open {
    .gjs-title {
        // border-left: 2px solid var(--primary500)!important;
        color: var(--primary500);
        background-color: #f7f8fa !important;
    }
}

#gjs-sm-move {
    color: black !important;

    &:hover {
        color: var(--primary500) !important;
    }
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-btn {
    // TO_CHECK
    @extend .gjs-rounded;

    color: var(--white);
    background-color: #629c1c;
    border-color: #629c1c;
    text-shadow: none;
    box-shadow: none;
    opacity: 1;
}

.gjs-layer {
    // &.gjs-hovered {
    // // &:not(.gjs-selected) {
    //     .gjs-layer-title {
    //         background-color: red;
    //     }
    // }

    // &.gjs-freezed {
    //     .gjs-layer-title {
    //         // background-color: transparent;
    //     }
    // }

    // &.open {
    //     .gjs-layer-caret::before {
    //         color: var(--primary500);
    //         color: red;
    //     }
    // }

    .gjs-layer-title {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        border-color: #ebedf2;
        font-weight: 400;
        background-color: #f7f8fa;

        .fa-chevron-down {
            color: var(--primary500);
            opacity: 1;
        }

        .gjs-no-app {
            height: auto;
            padding: 0;
        }
    }
    .gjs-layer-name {
        color: #161616;
    }

    .fa-chevron-down:before {
        content: "\f078";
    }

    .gjs-layer-caret {
        left: -10px;
        top: 5px;
        font-size: 0.6rem;
        // width: 12px;
    }

    .gjs-layer-vis,
    .gjs-layer-move {
        padding: 0.5rem 9px;
        top: 7px;
        color: var(--primary500);
    }

    .gjs-layer-count {
        top: 10px;
        right: 34px;
    }

    .gjs-layer-move {
        top: 4px;
        color: #161616;
        font-size: 1rem;

        &:hover {
            color: var(--primary500);
        }
    }
}

.gjs-title {
    border: 0 !important;
    padding-bottom: 0.7rem !important;
    padding-top: 0.7rem !important;
    font-weight: 400 !important;

    &:hover {
        color: var(--primary500);
        background-color: #f7f8fa !important;
    }
}

.gjs-fonts {
    // font-family: font3336;
    // font-weight: normal;

    &::before {
        font-size: 3em;
        height: 36px;
    }

    .gjs-block-label {
        color: #161616;
    }
}

.gjs-block.fa {
    &::before {
        display: block;
        height: 36px;
        line-height: 1.3;
    }

    .gjs-block-label {
        color: #161616;
    }
}

.gjs-block-svg {
    width: 56px;
    height: 36px;
    margin-bottom: auto;
}

.gjs-editor {
    font-weight: 400;

    .fa {
        display: inline-block;
        font-family: OldFontAwesome !important;
        font-weight: normal;
        font-style: normal;
    }
}

.gjs-trt-trait__wrp {
    &.is-invalid {
        .gjs-label {
            color: #fd397a;
        }

        // .gjs-field input {
        //     border-color: #fd397a!important;
        // }
    }
}

// Inputs
.gjs-field {
    border: 0;

    input,
    select,
    textarea {
        // TO_CHECK
        @extend .gjs-rounded;

        border: 1px solid #ebedf2 !important;
        height: 30px;

        &:focus {
            border: 1px solid #161616 !important;
            outline: none;
        }
    }

    &.gjs-field-multi-select {
        select {
            height: 200px;
        }
    }

    .gjs-radio-item input:checked + .gjs-radio-item-label {
        background-color: #629c1c;
        // font-weight: 500;
        color: var(--white);
    }

    .gjs-radio-item {
        border-left: 0;

        &:hover {
            background-color: #f7f8fa;
            color: var(--primary500);
        }
    }

    .gjs-radio-item-label {
        margin-bottom: 0 !important;
        height: 100%;
        line-height: 1.9;
    }

    .gjs-input-unit,
    .gjs-input-unit:focus {
        border: 0 !important;
    }

    .gjs-radio-items {
        // TO_CHECK
        @extend .gjs-rounded;

        overflow: hidden;
        box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
        border: 1px solid #ebedf2 !important;
    }
}

#gjs-clm-tags-field {
    border: 1px solid #ebedf2 !important;

    #gjs-clm-new {
        color: inherit;
    }
}

// Toolbar
.gjs-pn-commands {
    border-bottom: 1px solid #ebedf2;
    box-shadow: none;
    width: calc(100% - #{$leftWidth});
}

// Toolbar buttons
.gjs-pn-btn {
    width: 38px;
    height: 38px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &.gjs-pn-active {
        box-shadow: none;
        background-color: #f7f8fa;
    }

    &:hover {
        background-color: #f7f8fa;
        color: var(--primary500);
    }
}

// Aside switcher
.gjs-pn-views {
    border-bottom: 1px solid #ebedf2;
    border-left: 1px solid #ebedf2;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
}

// Aside
.gjs-pn-views-container {
    padding-top: 49px;
    border-left: 1px solid #ebedf2;
    box-shadow: none;
}

// Collapses
.gjs-layer,
.gjs-am-assets-cont,
.gjs-am-file-uploader > form,
.gjs-editor-cont ::-webkit-scrollbar-track,
.gjs-category-title,
.gjs-pn-panel .gjs-resizer-h,
.gjs-add-trasp:active,
.gjs-sm-input,
.gjs-sm-input,
.gjs-sm-integer,
.gjs-sm-list,
.gjs-sm-select,
.gjs-sm-composite,
.gjs-sm-color {
    background-color: white;
}

.gjs-block {
    border-color: #ebedf2;
    width: 28%;
}

// Blocks
.gjs-block {
    min-height: 74px;
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);

    &:hover {
        box-shadow: 0 0 13px 0 rgba(46, 45, 48, 0.11);
    }
}

// Viewport
.gjs-cv-canvas {
    background-color: #f9f9fc;
    top: 49px;
    width: calc(100% - #{$leftWidth});
    height: calc(100% - 49px);

    iframe {
        box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);
    }
}
