.filters__subtitle {
    padding: 24px 0 0;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: $text-base;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.filters__options {
    align-self: start;
    position: sticky !important;
    top: 100px;
    border-right: 1px solid var(--gray100);
    padding: 0 7px 56px 24px;

    @media (min-width: 1024px) {
        top: 176px !important;
    }

    .filters__container {
        max-height: calc(90vh - 180px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray200);
            border-radius: 8px;
        }
    }

    .filters__body {
        margin-bottom: 32px;

        h3 {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            text-transform: uppercase;
            color: var(--gray400);
        }

        .filters__filter {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            box-sizing: content-box;

            .refinement_list_checkbox {
                display: flex;
                gap: 8px;
                align-items: center;
                height: 30px;
                min-width: 170px;

                .checkbox {
                    margin: 0;
                    display: contents;

                    label {
                        margin-bottom: 0;
                        align-items: center;
                        line-height: 28px;
                        font-size: $text-sm;
                        color: var(--gray800) !important;
                        //text-transform: lowercase;
                        display: inline-flex !important;

                        /*    &::first-letter {
                            text-transform: uppercase;
                        } */

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            & > .orders__filters_options-filter_button {
                line-height: 20px;
                letter-spacing: -0.02em;
                text-align: center;
            }

            .active {
                color: var(--white) !important;
                background-color: var(--primary500) !important;

                .button_label {
                    .status__success {
                        color: var(--white);
                        background-color: var(--primary400);
                    }
                }
            }

            .filters_options-filter_button {
                padding: 10px 16px;
                min-width: 106px;
                white-space: normal;

                @media (min-width: 1556px) {
                    min-width: 138px;
                }

                .button_label {
                    display: flex;
                    gap: 8px;
                    justify-content: center;
                }
            }

            .ais-NumericMenu-list {
                width: 100%;
                padding-left: 0;
                display: flex;
                flex-direction: column;
                gap: 8px;
                list-style-type: none;
            }

            .form-check {
                padding-left: 0;
                display: flex !important;
                align-items: center !important;
                gap: 8px;

                label {
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    line-height: 28px;
                    font-size: $text-sm;
                    color: var(--gray800) !important;
                    display: inline-flex !important;

                    &::first-letter {
                        text-transform: uppercase;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                input[type="radio"] {
                    -webkit-appearance: none;
                    appearance: none;
                    background: var(--white);
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    border: 2px solid var(--gray300);
                    border-radius: 50%;

                    &:checked {
                        border-color: var(--primary500);
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 20 20'%3e%3ccircle r='6' fill='%2359D126' /%3e%3c/svg%3e");

                        &::before {
                            display: inline-block;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus {
                        border-color: var(--primary500);
                    }

                    &:disabled {
                        background: var(--gray200);
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .filters__body:last-of-type {
        margin-bottom: 0;
    }
}
