@media (min-width: 1025px) {
    .k-aside__brand {
        background-color: var(--primary800);
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .k-aside__brand .k-aside__brand-tools .k-aside__brand-aside-toggler span {
        background: var(--primary500);
    }
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler
        span::before,
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler
        span::after {
        background: var(--primary500);
    }
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler:hover
        span {
        background: var(--white);
    }
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler:hover
        span::before,
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler:hover
        span::after {
        background: var(--white);
    }
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler.k-aside__brand-aside-toggler--active
        span {
        background: var(--white);
    }
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler.k-aside__brand-aside-toggler--active
        span::before,
    .k-aside__brand
        .k-aside__brand-tools
        .k-aside__brand-aside-toggler.k-aside__brand-aside-toggler--active
        span::after {
        background: var(--white);
    }
}
@media (max-width: 1024px) {
    .k-header-mobile {
        background-color: var(--primary800);
        -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    }
    .k-header__topbar--mobile-on .k-header-mobile {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler
        span {
        background: var(--primary500);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler
        span::before,
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler
        span::after {
        background: var(--primary500);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler:hover
        span {
        background: var(--white);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler:hover
        span::before,
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler:hover
        span::after {
        background: var(--white);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler.k-header-mobile__toolbar-toggler--active
        span {
        background: var(--white);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler.k-header-mobile__toolbar-toggler--active
        span::before,
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-toggler.k-header-mobile__toolbar-toggler--active
        span::after {
        background: var(--white);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-topbar-toggler
        i {
        color: var(--primary500);
    }
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-topbar-toggler.k-header-mobile__toolbar-topbar-toggler--active
        i,
    .k-header-mobile
        .k-header-mobile__toolbar
        .k-header-mobile__toolbar-topbar-toggler:hover
        i {
        color: var(--white);
    }
}
