.Toastify__toast {
    @each $type,
        $color
            in (
                success: success,
                info: info,
                default: light,
                warning: warning,
                error: danger
            )
    {
        &.Toastify__toast--#{$type} {
            background-color: #{$color};
        }
    }

    .notification__body {
        display: flex;
        background-color: transparent;

        > i {
            @extend .fas, .fa-2x;

            margin-right: 0.85rem;
        }

        .notification__content {
            margin-top: 4px;
        }
    }
}
