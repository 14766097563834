.onfleet_driver__content {
    .onfleet_driver__table_row_primary {
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
    }

    .status {
        max-width: 70px !important;
        padding: 2px 8px !important;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        line-height: 16px;
        font-weight: 500;
        font-size: $text-xs;
    }

    .status-success {
        @extend .status;
        background-color: var(--blue50);
        color: var(--blue200);
    }

    .status-secondary {
        @extend .status;
        background-color: var(--gray100);
        color: var(--gray500);
    }

    .badge__status {
        display: inherit;
    }
}
