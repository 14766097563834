.modal {
    .modal-content {
        border: 0;

        .modal-header .close {
            @extend .fas, .fa-times;
        }

        .modal-footer > :not(:last-child) {
            margin-right: 0;
        }
    }
}
