.auth_container {
    div[data-test="authenticator-error"] {
        @extend .show, .fa-exclamation-circle;

        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        padding: 1.3rem 2rem;
        border-radius: 3px;
        margin: 0 auto 2rem auto;
        align-items: center !important;
        background-color: #f9f9fc;

        &::before {
            @extend .fas;

            font-size: 22px;
            margin-right: 16px;
        }

        width: 500px !important;
        color: #5578eb !important;
        box-shadow: none !important;
        margin-bottom: -82px !important;
        margin-top: 20px;
        position: static !important;
    }

    > div {
        @extend .k-login-v2__body;

        flex-direction: column !important;
        // display: block;
        align-items: stretch;
        font-weight: 400;
        width: 100%;
        min-height: 100vh;

        > div:not(.Toastify) {
            @extend .k-login-v2__body-wrapper;

            justify-content: center !important;
            margin-top: 100px !important;
            width: 100%;
            max-width: 500px;
            border-radius: 0.6rem;

            width: 50%;
            justify-content: flex-end;
            display: flex;

            text-align: center;
            margin: 5% auto 50px;

            > div {
                @extend .k-login-v2__body-container;

                position: relative;
                margin-bottom: 20px;
                background-color: #242939;
                padding: 35px 40px;
                text-align: left;
                display: inline-block;
                min-width: 460px;
                border-radius: 6px;
                box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                padding-left: 0 !important;
                padding-right: 0 !important;
                min-width: 500px !important;

                &::before {
                    margin: 1rem auto 3rem;
                    display: block;
                    content: "";
                    background-image: url(../../images/logo.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 45px;
                }

                > div {
                    color: #c5c5c5 !important;
                    padding: 0 4rem;
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                }

                form {
                    > div:nth-child(1) {
                        padding: 3rem 4rem;
                        margin-bottom: 30px;

                        > div {
                            margin-bottom: 22px;

                            @media (min-width: 1024px) {
                                margin-bottom: 2rem;
                            }

                            > div:nth-child(1) {
                                display: none;
                            }

                            input {
                                padding: 0.85rem 1rem;
                                display: block;
                                width: 100%;
                                padding: 1rem;
                                font-size: 1rem;
                                line-height: 1.5;
                                color: #b8b8b8;
                                background-color: #1f2433;
                                background-clip: padding-box;
                                border: none !important;
                                border-radius: 0.25rem;
                                transition: border-color 0.15s ease-in-out,
                                    box-shadow 0.15s ease-in-out;
                                border-top: 0;
                                border-left: 0;
                                border-right: 0;
                                border-radius: 0;
                                margin-bottom: 16px;

                                &::placeholder {
                                    opacity: 0.75;
                                }

                                &:focus {
                                    outline: 0;
                                    color: #c5c5c5;
                                    background-color: #1f2433;
                                    border-color: #fff;
                                    outline: 0;
                                    box-shadow: 0 0 0 0.2rem
                                        rgba(88, 103, 221, 0.25);
                                    box-shadow: none;
                                }
                            }

                            > div:last-child {
                                color: #c5c5c5;
                                font-size: 12px;

                                a {
                                    cursor: pointer;
                                    color: var(--primary500) !important;
                                }
                            }
                        }
                    }

                    > div:nth-child(2) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 0 4rem;
                        font-size: 14px;
                        color: #828282;
                        display: flex;
                        flex-direction: row-reverse;

                        button {
                            padding: 1rem 3.5rem;
                            border-radius: 0.25rem;
                            color: #fff;
                            font-size: 1rem;
                            cursor: pointer;
                            align-items: center;
                            background-color: var(--primary500);
                            border-color: var(--primary500);
                            font-weight: 700;
                            min-width: 153px;
                            display: inline-block;
                            margin-bottom: 0;
                            line-height: 1.42857143;
                            text-align: center;
                            white-space: nowrap;
                            vertical-align: middle;
                            touch-action: manipulation;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            background-image: none;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 14px 0;
                            letter-spacing: 1.1px;
                            border: none;

                            &:disabled {
                                pointer-events: none;
                                opacity: 0.8;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                @keyframes rotate {
                                    0% {
                                        transform: rotate(0deg);
                                    }
                                    100% {
                                        transform: rotate(360deg);
                                    }
                                }

                                &::before {
                                    content: "";
                                    display: inline-block;
                                    width: 15px;
                                    height: 15px;
                                    border: 2px solid white;
                                    border-left-color: transparent;
                                    border-radius: 50%;
                                    animation: rotate 0.7s linear infinite;
                                    margin-right: 12px;
                                }
                            }
                        }
                    }
                }

                > div:nth-child(2) {
                    padding: 3rem 4rem;
                    margin-bottom: 30px;

                    > div {
                        margin-bottom: 22px;

                        @media (min-width: 1024px) {
                            margin-bottom: 2rem;
                        }

                        > div {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;

                            input[name="contact"] {
                                width: auto;
                                margin-bottom: 0;
                            }

                            label {
                                margin-bottom: 0;
                            }

                            > div {
                                display: none;
                            }
                        }

                        input {
                            padding: 0.85rem 1rem;
                            display: block;
                            width: 100%;
                            padding: 1rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            color: #b8b8b8;
                            background-color: #1f2433;
                            background-clip: padding-box;
                            border: none !important;
                            border-radius: 0.25rem;
                            transition: border-color 0.15s ease-in-out,
                                box-shadow 0.15s ease-in-out;
                            border-top: 0;
                            border-left: 0;
                            border-right: 0;
                            border-radius: 0;
                            margin-bottom: 16px;

                            &::placeholder {
                                opacity: 0.5;
                            }

                            &:focus {
                                outline: 0;
                                color: #c5c5c5;
                                background-color: #1f2433;
                                border-color: #fff;
                                outline: 0;
                                box-shadow: 0 0 0 0.2rem
                                    rgba(88, 103, 221, 0.25);
                                box-shadow: none;
                            }
                        }

                        > div:last-child {
                            color: #828282;
                            font-size: 12px;

                            a {
                                cursor: pointer;
                                color: var(--primary500) !important;
                            }
                        }
                    }
                }

                > div:nth-child(3) {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                    align-items: center;

                    button {
                        padding: 1rem 3.5rem;
                        border-radius: 0.25rem;
                        color: #fff;
                        font-size: 1rem;
                        cursor: pointer;
                        align-items: center;
                        background-color: var(--primary500);
                        border-color: var(--primary500);
                        font-weight: 700;
                        min-width: 153px;
                        display: inline-block;
                        margin-bottom: 0;
                        line-height: 1.42857143;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        touch-action: manipulation;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        background-image: none;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 14px 0;
                        letter-spacing: 1.1px;
                        border: none;

                        &:disabled {
                            pointer-events: none;
                            opacity: 0.8;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            @keyframes rotate {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }

                            &::before {
                                content: "";
                                display: inline-block;
                                width: 15px;
                                height: 15px;
                                border: 2px solid white;
                                border-left-color: transparent;
                                border-radius: 50%;
                                animation: rotate 0.7s linear infinite;
                                margin-right: 12px;
                            }
                        }
                    }

                    a {
                        font-size: 14px;
                        cursor: pointer;
                        color: var(--primary500) !important;
                    }
                }
                a {
                    // @extend .k-link, .k-link--brand;

                    color: var(--primary500) !important;
                }
            }
        }
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: #c5c5c5 !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #1f2433 inset !important;
    }
}
