.promotion__content {
    .row-expansion-style {
        strong {
            color: var(--gray500);
        }
    }
}

.promotion__form {
    .k-portlet__body {
        padding: 32px 32px 80px 20px;
    }

    .k-section__title {
        padding-left: 12px;
    }

    .promotion__broadcast_color {
        max-width: 230px;
        width: 50%;
        height: 40px;
        border: 1px solid var(--gray300);
        border-radius: 4px;

        &:hover {
            border: 1px solid var(--gray500);
        }
    }

    .sketchpicker {
        position: relative;
    }

    .promotion__picker_color {
        position: absolute;
        bottom: 118%;
        z-index: 2;
    }

    .promotion__picker_color_hidden {
        display: none;
    }
}
