.search__gallery {
    display: flex;
    justify-content: space-between;

    .k-input-icon {
        max-width: 250px;
        width: 100%;
    }

    .gallery__input {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;

        &::-ms-browse,
        &::-webkit-file-upload-button {
            cursor: pointer;
        }
    }
}

.gallery__body {
    background: #f9f9fc;
    padding-bottom: 30px;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, 110px);
    column-gap: 18px;
    row-gap: 18px;
}

.gallery__card {
    position: relative;
    height: 110px;
    width: 100%;
    transition: 0.3s;
    background: white;
    margin-bottom: 0;
    overflow: hidden;
    box-shadow: 2px 2px 2px 0 rgba(105, 63, 63, 0.05);

    &.gallery__card_selectable {
        cursor: pointer;
    }

    &[aria-checked="true"] {
        outline: 0 0 0 1px black;
    }

    &:hover .image__title {
        display: flex;
    }
}

.gallery__card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.image__title {
    display: none;
    position: absolute;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    bottom: 0;
    font-size: 10px;
    width: 100%;
    height: 100%;
    padding: 4px;
    overflow: hidden;
    color: white;
    background: var(--primary500);

    .btn + .btn {
        margin-left: 4px;
    }
}

.image-preview {
    width: 150px;
    height: 150px;
    margin-top: 16px;
    border: 1px solid #ebedf2;
    object-fit: contain;
    background: #f9f9fc;
}
