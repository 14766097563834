.k-form {
    @include k-desktop {
        &.k-form--centered {
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    label {
        color: var(--gray400);
        margin-bottom: 8px;
        line-height: 16px;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.01em;
    }
}

.input-group.is-invalid ~ .invalid-feedback {
    display: block;
}

.input-highlight-container {
    position: relative;
    flex-grow: 1;

    &:focus-within {
        z-index: 3;
    }

    &.input-highlight-container_prepend {
        [data-input] {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.input-highlight-container_append {
        [data-input] {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.input-highlight {
    position: absolute !important;
    top: 0;
    left: 0;
    border-color: transparent;
    pointer-events: none;
    background-color: transparent;
    color: transparent;
    word-wrap: break-word;
    margin: 0.65rem 1rem;
    padding: 0;
    white-space: pre;
    overflow: hidden;
    height: calc(100% - 1.3rem);
    width: calc(100% - 2rem);

    &[data-type="textarea"] {
        overflow: auto;
        margin: 0;
        padding: 0.65rem 1rem;
        white-space: pre-wrap;
        height: 100%;
        width: 100%;
    }

    mark {
        background-color: var(--primary50);
        color: var(--gray400);
        opacity: 0.5;
        padding: 0;
        border-radius: 3px;
    }
}

.working__hours-decorator {
    display: none;

    @media (min-width: 768px) { 
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #D5D9E5;
        background: #F9FAFC;
        height: 35.59px;
        width: 40px;
    }

    @media (min-width: 1024px)  {
      height: 41.19px;
    }
  
}

.menu__express-hours {
    margin-top: 25.19px;

    @media (min-width: 1024px)  {
      margin-top: 26.80px;
      height: 42.19px;
    }
}

.menu__express-label-fill {
    padding-top: 12px;
    
    @media (min-width: 1024px)  {
      padding-top: 13px;
    }
}

.no-border-right {
  @media (min-width: 768px) {
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
 
}

.no-border-left {
  @media (min-width: 768px) {
    border-left: none !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
