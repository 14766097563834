.badge__status {
    background: transparent;
    text-align: center;
    font-size: $text-xs;
    color: var(--gray800);
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.01em;
    padding: 2px 8px;
    display: block;
    font-weight: 500;
    min-width: 25px;
    max-height: 20px;
    border-radius: 2px;
    max-width: fit-content;
}

.status__metalic {
    background: var(--gray100);
    color: var(--gray500);
}

.status__success {
    background: var(--primary50);
    color: var(--primary800);
}

.status__info {
    background: var(--blue50);
    color: var(--blue200);
}

.status__warning {
    background: var(--yellow50);
    color: var(--yellow200);
}

.status__error {
    background: var(--red50);
    color: var(--red200);
}

.badge__status-md {
    font-size: $text-sm !important;
    line-height: 20px;
    max-height: 24px;
}
