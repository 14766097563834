.accordion {
    &.accordion-light {
        .card {
            &:last-child {
                margin-bottom: 0;
            }

            .card-header {
                .card-title {
                    color: inherit;
                    // font-size: 1.1rem;
                    // font-weight: 400;

                    &.is-invalid {
                        color: var(--danger);

                        &::after {
                            color: var(--danger);
                        }
                    }

                    &.collapsed {
                        &::after {
                            transform: rotateZ(-90deg);
                        }
                    }

                    &::after {
                        @extend .fas;

                        color: inherit;
                        content: '\f078' !important;
                        transition: all 0.3s ease;
                    }

                    > i {
                        font-size: 1.3rem;
                        margin-right: 0.8rem;
                    }
                }
            }
        }
    }
}
