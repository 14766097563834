.paragraph__description_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .paragraph__description {
        letter-spacing: -0.2px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
    }

    button {
        font-size: 12px !important;
        height: 16px !important;
        padding: 0 !important;
        width: fit-content;
        min-width: 40px !important;
        color: var(--gray400) !important
    }

    .show_more {
        -webkit-line-clamp: unset;
        line-clamp: unset;
        display: block;
    }

    .show_more_button {
        align-self: flex-end;
        font-size: $text-sm;
        font-weight: bold;
    }
}