.rejt-array-node,
.rejt-object-node {
    > span > .rejt-name {
        cursor: pointer;
    }

    &::before {
        content: "►";
        margin-right: 3px;
        font-size: 11px;
    }

    button {
        height: 2.25rem;
        width: 2.25rem;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        outline: none;
        margin-left: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        color: #586272 !important;
        background: transparent;

        &:hover {
            background: #d3dae6;
            border-color: #d3dae6;
        }
    }

    input {
        @extend .form-control, .form-control-sm;

        display: inline-block;
        width: auto;
        height: 31.5px;
    }

    .rejt-name {
        color: var(--primary500) !important;
    }

    .rejt-value {
        color: #1dc9b7 !important;
        word-break: break-all;
    }

    .rejt-collapsed-text {
        color: #6c757d !important;
    }
}
