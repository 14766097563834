.k-action {
    @at-root {
        .k-header__topbar--mobile-on & {
            top: 110px;
        }
    }

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin-top: -2rem;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: inherit;
    position: sticky;
    top: 60px;
    transition: top 0.3s ease;
    z-index: 90;

    .k-action__title {
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
        font-weight: 500;
        color: #161616;

        small {
            font-weight: 300;
            margin-left: 0.7rem;
            font-size: 1rem;
            color: #adb5ae;
        }
    }

    .k-action__toolbar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: end;
        align-content: flex-end;
        margin-top: 1.3rem;
        flex-wrap: wrap;

        :nth-child(1) {
            margin-left: 0!important;
        }
    }

    @include k-desktop {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: flex-first;
        align-content: flex-first;
        flex-direction: row;
        top: 75px!important;
        margin-left: -25px;
        margin-right: -25px;
        padding-left: 25px;
        padding-right: 25px;

        .k-action__toolbar {
            margin-left: auto;
            margin-top: 0;
        }
    }
}
