.dasboard__body {
    [class|="col"] {
        padding: 0 8px !important;
    }

    .dasboard__graphics {
        .k-portlet__head {
            padding: 24px 24px 16px 24px;
        }

        .k-portlet__body {
            padding: 24px;
        }

        .k-portlet__head-title {
            display: flex;
            flex-direction: column;
            font-size: $text-base !important;
            letter-spacing: -0.02em !important;
            font-weight: 400 !important;
            color: var(--gray400) !important;

            small {
                padding-left: 0 !important;
                font-size: $text-2xl !important;
                font-weight: 700 !important;
                color: var(--gray700) !important;
            }
        }
    }

    .dashboard__stats {
        display: grid;
        grid-template-columns: minmax(218px, 1fr);
        padding: 0 8px !important;
        column-gap: 24px;

        @media (min-width: 420px) {
            grid-template-columns: repeat(2, minmax(218px, 1fr));
        }

        @media (min-width: 768px) {
            grid-template-columns: repeat(3, minmax(218px, 1fr));
        }

        @media (min-width: 1820px) {
            grid-template-columns: repeat(6, minmax(218px, 1fr));
        }
    }
}

.stat_card {
    margin-bottom: 24px;

    .k-portlet__body {
        padding: 12px;
        min-width: 218px;
        flex-grow: 1;
    }

    .stat_card-container {
        display: grid;
        grid-template-columns: 1fr auto;

        .stat_card-stats {
            .stat_card-title {
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: var(--gray400);
                margin-bottom: 8px;
            }

            .stat_card-main_stat {
                font-weight: 700;
                font-size: 20px;
                line-height: 26px;
                letter-spacing: 0.5px;
                color: var(--gray700);
                margin-bottom: 16px;
            }

            .stat_card-percent_stat {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.3px;
                margin-bottom: 0px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 4px;
                height: 16px;

                .percent_stat-success {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 4px;
                    height: 16px;
                    color: var(--primary800);

                    svg {
                        * {
                            stroke: var(--primary800);
                        }
                    }
                }
                .percent_stat-error {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0px;
                    gap: 4px;
                    height: 16px;
                    color: var(--red500);

                    svg {
                        transform: rotate(90deg);
                        * {
                            stroke: var(--red500);
                        }
                    }
                }
                .stat_desc {
                    color: var(--gray400);
                    width: max-content;
                }
            }
        }

        .stat_card-icon_badge {
            display: flex;
            place-items: center;
            padding: 8px;
            width: 32px;
            height: 32px;
            background: var(--green50);
            border-radius: 8px;
        }
    }
}
