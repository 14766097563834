.filter-select-wraper {
    .k-filter-input {
        > div {
            align-items: center;
        }
    }

    input {
        padding: 5px 42px !important;
    }

    .k-btn-arrow-icon {
        width: 3rem !important;
        //
        > span > i {
            font-size: 9px;
        }
    }

    .k-btn-arrow-icon:hover {
        /*   span > i {
            color: var(--primary500);
        } */
        /*   color: var(--primary500);
        background-color: var(--gray50);
        border: 1px solid var(--gray200);
        //border-left: 0;
        border-radius: 0 4px 4px 0; */
    }

    .separator {
        width: 1px !important;
        height: 16px;
        border-left: 1px solid var(--gray200);
    }

    .dropdown-menu {
        top: 40px;
        border-radius: 8px;
        border: 1px solid var(--gray100) !important;
        box-shadow: 2px 2px 8px rgba(175, 181, 199, 0.25);
        right: 0 !important;
        font-weight: 500;

        .dropdown-menu-body {
            overflow-y: auto;
            max-height: 250px;
            scroll-behavior: smooth;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .dropdown-header {
            text-align: left !important;
            font-size: $text-small;
            letter-spacing: 0.01em;
            line-height: 14px !important;
            padding: 9px 20px;
            text-transform: uppercase;
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;
            padding: 6px 20px;
            font-weight: 500;
            font-size: $text-sm;
            line-height: $text-base !important;
            color: var(--gray600);
            letter-spacing: -0.02em;
            white-space: normal;

            &:hover {
                color: var(--primary500);
            }

            &--no-stores {
                cursor: unset;
                justify-content: center;
                &:hover {
                    color: inherit;
                    background-color: inherit;
                }
            }
        }
    }

    /*  &:active,
    &:focus {
        .k-btn-arrow-icon:hover {
            background-color: var(--gray50);
            border: 1px solid var(--gray600);
            //border-left: 0;
            border-radius: 0 4px 4px 0;
        }
    } */
}
