.k-aside {
    background-color: var(--primary800);
}
.k-aside .ps > .ps__rail-x {
    background: transparent !important;
}
.k-aside .ps > .ps__rail-x:hover,
.k-aside .ps > .ps__rail-x:focus {
    opacity: 1;
    background: transparent !important;
}
.k-aside .ps > .ps__rail-x:hover > .ps__thumb-x,
.k-aside .ps > .ps__rail-x:focus > .ps__thumb-x {
    opacity: 1;
}
.k-aside .ps > .ps__rail-x > .ps__thumb-x {
    background: var(--primary100);
    opacity: 1;
}
.k-aside .ps > .ps__rail-x > .ps__thumb-x:hover,
.k-aside .ps > .ps__rail-x > .ps__thumb-x:focus {
    opacity: 1;
    background: var(--primary100);
}
.k-aside .ps > .ps__rail-y {
    background: transparent !important;
}
.k-aside .ps > .ps__rail-y:hover,
.k-aside .ps > .ps__rail-y:focus {
    background: transparent !important;
    opacity: 1;
}
.k-aside .ps > .ps__rail-y:hover > .ps__thumb-y,
.k-aside .ps > .ps__rail-y:focus > .ps__thumb-y {
    opacity: 1;
}
.k-aside .ps > .ps__rail-y > .ps__thumb-y {
    background: var(--primary100);
    opacity: 1;
}
.k-aside .ps > .ps__rail-y > .ps__thumb-y:hover,
.k-aside .ps > .ps__rail-y > .ps__thumb-y:focus {
    opacity: 1;
    background: var(--primary100);
}
.k-aside .k-aside__footer {
    background-color: var(--primary800);
}
.k-aside .k-aside__footer .btn {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: transparent;
}
.k-aside .k-aside__footer .btn i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--primary100);
}
.k-aside .k-aside__footer .show .btn,
.k-aside .k-aside__footer .btn:hover {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background-color: var(--primary700);
}
.k-aside .k-aside__footer .show .btn i,
.k-aside .k-aside__footer .btn:hover i {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: var(--primary500);
}
.k-aside-menu {
    background-color: var(--primary800);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-text {
    color: var(--gray200);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__link-icon {
    color: var(--primary100);

    svg {
        * {
            stroke: var(--primary100);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary100);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary100);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__link .k-menu__ver-arrow {
    color: var(--primary100);
}
.k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__heading,
.k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--open > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);

    svg {
        * {
            stroke: var(--primary500);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__heading,
.k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--here > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);

    svg {
        * {
            stroke: var(--primary500);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu .k-menu__nav > .k-menu__item.k-menu__item--active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__heading,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);

    svg {
        * {
            stroke: var(--primary500);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);

    svg {
        * {
            stroke: var(--primary500);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu .k-menu__nav > .k-menu__item > .k-menu__arrow {
    color: var(--primary800);
}
.k-aside-menu .k-menu__nav > .k-menu__section .k-menu__section-text {
    color: var(--white);
}
.k-aside-menu .k-menu__nav > .k-menu__section .k-menu__section-icon {
    color: var(--gray400);
}
.k-aside-menu .k-menu__nav > .k-menu__separator {
    border-bottom: 1px solid var(--gray400);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__link
    .k-menu__link-text {
    color: var(--gray200);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--gray400);

    svg {
        * {
            stroke: var(--gray400);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--gray400);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--gray400);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--gray400);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__heading,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__link {
    background-color: red;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);

    svg {
        * {
            stroke: var(--primary500);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--open
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__heading,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);

    svg {
        * {
            stroke: var(--primary500);
        }
    }
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--here
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__heading,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item.k-menu__item--active
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link {
    background-color: var(--primary700);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-text,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-text {
    color: var(--white);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-icon,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-icon {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--dot
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__link-bullet.k-menu__link-bullet--line
    > span {
    background-color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__heading
    .k-menu__ver-arrow,
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
        .k-menu__item--here
    ):not(.k-menu__item--active):hover
    > .k-menu__link
    .k-menu__ver-arrow {
    color: var(--primary500);
}
.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__section
    .k-menu__section-text {
    color: var(--white);
}

.k-aside-menu
    .k-menu__nav
    > .k-menu__item
    .k-menu__submenu
    .k-menu__section
    .k-menu__section-icon {
    color: var(--gray400);
}
.k-aside-menu .k-menu__nav > .k-menu__item .k-menu__submenu .k-menu__separator {
    border-bottom: 1px solid var(--gray400);
}
@media (min-width: 1025px) {
    .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item {
        background: transparent;
    }
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item
        > .k-menu__link
        > .k-menu__link-icon {
        color: var(--gray400);
    }
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--hover,
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--open {
        background: transparent !important;
    }
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--hover
        > .k-menu__link
        > .k-menu__link-icon,
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--open
        > .k-menu__link
        > .k-menu__link-icon {
        color: var(--primary500);
    }
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--here,
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--active {
        background: transparent !important;
    }
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--here
        > .k-menu__link
        > .k-menu__link-icon,
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item.k-menu__item--active
        > .k-menu__link
        > .k-menu__link-icon {
        color: var(--primary500);
    }
    .k-aside--minimize .k-aside-menu .k-menu__nav > .k-menu__item:hover {
        background: transparent;
    }
    .k-aside--minimize
        .k-aside-menu
        .k-menu__nav
        > .k-menu__item:hover
        > .k-menu__link
        > .k-menu__link-icon {
        color: var(--primary500) !important;
    }
}
@media (min-width: 1025px) {
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav {
        background-color: var(--white);
        -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-text,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-text {
        color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-icon,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-icon {
        color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span {
        background-color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span {
        background-color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__ver-arrow,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__heading
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item
        > .k-menu__link
        .k-menu__ver-arrow {
        color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link {
        background-color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-text,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-text {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-icon,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-icon {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__ver-arrow,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__heading
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--open
        > .k-menu__link
        .k-menu__ver-arrow {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__heading,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__link,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__heading,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__link {
        background-color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--here
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link {
        background-color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-text,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-text {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-icon,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-icon {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__ver-arrow,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__heading
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item.k-menu__item--active
        > .k-menu__link
        .k-menu__ver-arrow {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link {
        background-color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-text,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-text {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-icon,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-icon {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--dot
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__link-bullet.k-menu__link-bullet--line
        > span {
        background-color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__ver-arrow,
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__heading
        .k-menu__ver-arrow,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__item:not(.k-menu__item--parent):not(.k-menu__item--open):not(
            .k-menu__item--here
        ):not(.k-menu__item--active):hover
        > .k-menu__link
        .k-menu__ver-arrow {
        color: var(--primary500);
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__section
        .k-menu__section-text,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__section
        .k-menu__section-text {
        color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__section
        .k-menu__section-icon,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__section
        .k-menu__section-icon {
        color: red;
    }
    .--minimize
        .k-aside-menu
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__separator,
    .k-aside-menu.k-aside-menu--dropdown
        .k-menu__nav
        .k-menu__item:not([k-menu-submenu-mode="accordion"]).k-menu__item--hover
        > .k-menu__submenu
        .k-menu__subnav
        .k-menu__separator {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
}
.k-aside
    .k-aside-menu
    > .k-menu__nav
    .k-menu__item
    > .k-menu__link
    [class^="flaticon2-"],
.k-aside
    .k-aside-menu
    > .k-menu__nav
    .k-menu__item
    > .k-menu__link
    [class*=" flaticon2-"] {
    color: red;
}
