.treez {
    &_modal_product {
        @media (min-width: 576px) {
            max-width: 80vw;
        }

        @media (min-width: 1556px) {
            max-width: 75vw;
        }
    }

    &__search {
        height: 40px;
    }

    &__table {
        &__barcodes {
            column-gap: 6px;
            display: flex;
        }
    }

    .k-portlet__body {
        padding-top: 0 !important;
    }

    .treez__select-store {
        .react-select__menu {
            height: fit-content
        }

        width: 350px;
        > div {
            height: 41.45px;
        }
    }

    .treez__input-search {
        position: sticky;
        top: 164px;
        padding: 32px 0;
        background: var(--white);
        z-index: 2;
    }

    .treez__table_row_primary {
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
    }

    .treez__table_row_secondary {
        line-height: 20px;
        letter-spacing: -0.02em;
        color: var(--gray400);
    }
}
